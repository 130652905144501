import Modal from "react-modal";
import "./index.scss";

interface ModalProps {
  isOpen: boolean;
  children: React.ReactNode;
  onRequestClose: () => void;
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    backgroundColor: "transparent",
    transform: "translate(-50%, -50%)",
    border: "none",
    zIndex: "1000"
  },
  overlay: {
    zIndex: "1000"
  }
};

export default function RModal(props: ModalProps) {
  return (
    <Modal style={customStyles} {...props}>
      {props.children}
    </Modal>
  );
}
