import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../redux";
import { useAppSelector } from "../../../redux/hooks";
import { sessionRoomSelector } from "../../../redux/sessionRoomReducer/selectors";
import { socketDisconnectedSelector } from "../../../redux/globalErrorStateReducer/selectors";
import { closeConnection, openConnection } from "../../../services/socketsHandler";
import Estimation from "../../content/Estimation";
import SocketDisconnectedModal from "../../content/SocketDisconnectedModal";
import StoryList from "../../content/StoryList";
import Users from "../../content/Users";

export default function MainContent() {
  const { userName, userId } = useAppSelector((state: RootState) => state.userReducer);
  const session = useAppSelector(sessionRoomSelector);
  const { connectionStatus } = useAppSelector(socketDisconnectedSelector);
  const navigate = useNavigate();

  useEffect(() => {
    const sessionRoomId = window.location.pathname.split("/").pop();
    if (!sessionRoomId) {
      console.log("FIX ME");
      navigate("/", { replace: true });
      return;
    }
    openConnection(userName || "", sessionRoomId, userId || "");
    return function () {
      closeConnection();
    };
  }, []);

  useEffect(() => {
    if (session) {
      document.title = session.title;
    }
  });

  if (connectionStatus === "disconnected") {
    return <SocketDisconnectedModal />;
  }

  return (
    <div className="session-page-main-content">
      <div className="session-page-main-content__left-column">
        <StoryList />
      </div>
      <div className="session-page-main-content__center-column">
        <Estimation />
      </div>
      <div className="session-page-main-content__right-column">
        <Users />
      </div>
    </div>
  );
}
