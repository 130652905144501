import { Session } from "../../../redux/dashboardReducer";

interface SessionExpirationInfoProps {
  closeModal: () => void;
  session: Session;
}

export default function SessionExpirationInfo(props: SessionExpirationInfoProps) {
  const formatTime = (date: string) => {
    if (!date) {
      return;
    }
    return new Date(date).toLocaleDateString();
  };

  return (
    <form className="rmodal">
      <p className="rmodal__title">Expiration date:</p>
      <div className="input-wrapper">
        <span className="input-carrets">{">>>"}</span>
        <input className="input" disabled value={props.session.title} />
        <span className="input-carrets">{"<<<"}</span>
      </div>
      <div className="input-wrapper">
        <span className="input-carrets">{">>>"}</span>
        <input className="input" disabled value={formatTime(props.session.expireDate || "")} />
        <span className="input-carrets">{"<<<"}</span>
      </div>
      <div className="rmodal-actions">
        <a className="button button--fuschia" onClick={props.closeModal}>
          Close
        </a>
      </div>
    </form>
  );
}
