import React, { useState } from "react";
import { useAppSelector } from "../../../redux/hooks";
import { userSelector } from "../../../redux/userReducer/selectors";
import { useAppDispatch } from "../../../redux/hooks";
import { setUserName as updateName } from "./../../../redux/userReducer";
import "./styles.scss";

interface UserNameModalProps {
  redirectHandler: () => void;
}

export default function UserNameModal(props: UserNameModalProps) {
  const user = useAppSelector(userSelector);
  const [userName, setUserName] = useState(user.userName || "");
  const dispatch = useAppDispatch();

  const onUserNameChange = (event: React.FormEvent<HTMLInputElement>) => {
    setUserName(event.currentTarget.value);
  };

  const onCancel = () => {
    props.redirectHandler();
  };

  const submitUserName = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(updateName(userName));
  };

  return (
    <form className="rmodal" onSubmit={submitUserName}>
      <p className="rmodal__title">Enter Your Name</p>
      <div className="input-wrapper">
        <span className="input-carrets">{">>>"}</span>
        <input className="input" autoFocus={true} value={userName} type="text" onInput={onUserNameChange} placeholder="Name" />
        <span className="input-carrets">{"<<<"}</span>
      </div>
      <div className="rmodal-actions">
        <a className="button button--fuschia" onClick={onCancel}>
          Cancel
        </a>
        <button type="submit" className="button button--blue">
          Join
        </button>
      </div>
    </form>
  );
}
