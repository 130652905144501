import React, { useState } from "react";
import { setFinalVote as updateVoteForActiveStory } from "../../../services/socketsHandler";

interface FinalVoteProps {
  finalVote?: string;
  closeModal: () => void;
}

export default function FinalVote(props: FinalVoteProps) {
  const [finalVote, setFinalVote] = useState(props.finalVote || "");

  const handleFinalVoteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const finalVoteValue = e.target.value;
    setFinalVote(finalVoteValue);
  };

  const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    updateVoteForActiveStory(finalVote);
    props.closeModal();
  };

  return (
    <form className="rmodal" onSubmit={handleSave}>
      <p className="rmodal__title">Set Final Vote</p>
      <div className="input-wrapper">
        <span className="input-carrets">{">>>"}</span>
        <input className="input" autoFocus={true} onChange={handleFinalVoteChange} value={finalVote} placeholder="100500" />
        <span className="input-carrets">{"<<<"}</span>
      </div>
      <div className="rmodal-actions">
        <a className="button button--fuschia" onClick={props.closeModal}>
          Cancel
        </a>
        <button type="submit" className="button button--blue">
          Save
        </button>
      </div>
    </form>
  );
}
