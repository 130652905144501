import { removeStoryUrlMapping, storyUrlMapping } from "../../../redux/storyUrlMappingReducer";
import { useAppDispatch } from "../../../redux";
import React from "react";

interface RemoveStoryUrlMappingProps {
  closeModal: () => void;
  storyUrlMapping: storyUrlMapping;
}

export default function RemoveStoryUrlMapping(props: RemoveStoryUrlMappingProps) {
  const dispatch = useAppDispatch();

  const removeHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(removeStoryUrlMapping(props.storyUrlMapping._id));
    props.closeModal();
  };

  return (
    <form className="rmodal" onSubmit={removeHandler}>
      <p className="rmodal__title">Delete Story Mapping</p>
      <div className="input-wrapper">
        <span className="input-carrets">{">>>"}</span>
        <input className="input" disabled value={props.storyUrlMapping.storyNaming} />
        <span className="input-carrets">{"<<<"}</span>
      </div>
      <div className="rmodal-actions">
        <a className="button button--fuschia" onClick={props.closeModal}>
          No
        </a>
        <button type="submit" className="button button--blue" autoFocus={true}>
          Yes
        </button>
      </div>
    </form>
  );
}
