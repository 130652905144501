import "./App.scss";
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import history from "../../../utils/routerUtils";
import DashboardPage from "../../pages/DashboardPage";
import SessionPage from "../../pages/SessionPage";
import HomePage from "../../pages/HomePage";
import BrowserRouter from "../BrowserRouter";
import Header from "../Header";
import NotificationList from "../NoticiationList";
import SocketUsagePage from "../../pages/SocketUsagePage";
import OrganisationsPage from "../../pages/OrganisationsPage";
import OrganisationPage from "../../pages/OrganisationPage";
import BackupsPage from "../../pages/BackupsPage";
import { useAppDispatch } from "../../../redux";
import { getCurrentUser } from "../../../redux/userReducer";

function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCurrentUser());
  }, []);

  return (
    <BrowserRouter history={history}>
      <NotificationList />
      <Header />
      <Routes>
        <Route path="dashboard" element={<DashboardPage />} />
        <Route path="socket-usage" element={<SocketUsagePage />} />
        <Route path="organisations" element={<OrganisationsPage />} />
        <Route path="planningsession/:id" element={<SessionPage />} />
        <Route path="organisation/:id" element={<OrganisationPage />} />
        <Route path="backups" element={<BackupsPage />} />
        <Route path="" element={<HomePage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
