import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import history from "../../../utils/routerUtils";
import { createSession } from "../../../redux/dashboardReducer";
import { useAppDispatch } from "../../../redux";
import RModal from "../Modal";
import "./index.scss";

export default function NewSession() {
  const dispatch = useAppDispatch();
  const [title, setTitle] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const page = parseInt(searchParams.get("page") || "0");

  const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!title) {
      return;
    }
    dispatch(createSession(title));

    setModalOpen(false);
    setTitle("");

    if (page !== 0) {
      history.push("/dashboard?page=0");
    }
  };

  const handleClose = () => {
    setModalOpen(false);
    setTitle("");
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const titleValue = e.target.value;
    if (titleValue) {
      setTitle(titleValue);
    } else {
      setTitle("");
    }
  };

  return (
    <div className="new-session">
      <a className="button button--purple" onClick={() => setModalOpen(true)}>
        Create
      </a>
      <RModal isOpen={isModalOpen} onRequestClose={handleClose}>
        <form className="rmodal" onSubmit={handleSave}>
          <p className="rmodal__title">Enter Session Title</p>
          <div className="input-wrapper">
            <span className="input-carrets">{">>>"}</span>
            <input className="input" autoFocus={true} name="title" id="title" onChange={handleChange} value={title} />
            <span className="input-carrets">{"<<<"}</span>
          </div>
          <div className="rmodal-actions">
            <a className="button button--fuschia" onClick={handleClose}>
              Cancel
            </a>
            <button className="button button--blue" type="submit">
              Create
            </button>
          </div>
        </form>
      </RModal>
    </div>
  );
}
