import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import history from "../../../utils/routerUtils";
import { createOrganisation } from "../../../redux/applicationAdminDashboardReducer";
import { useAppDispatch } from "../../../redux";
import RModal from "../Modal";
import "./index.scss";

export default function NewOrganisation() {
  const dispatch = useAppDispatch();
  const [title, setTitle] = useState("");
  const [shortTitle, setShortTitle] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const page = parseInt(searchParams.get("page") || "0");

  const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!title && !shortTitle) {
      return;
    }

    dispatch(createOrganisation({ title: title, shortTitle: shortTitle }));

    setModalOpen(false);
    setTitle("");
    setShortTitle("");

    if (page !== 0) {
      history.push("/application-admin-dashboard?page=0");
    }
  };

  const handleClose = () => {
    setModalOpen(false);
    setTitle("");
    setShortTitle("");
  };

  const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const titleValue = e.target.value;
    if (titleValue) {
      setTitle(titleValue);
    } else {
      setTitle("");
    }
  };

  const handleChangeShortTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const shortTitleValue = e.target.value;
    if (shortTitleValue) {
      setShortTitle(shortTitleValue);
    } else {
      setShortTitle("");
    }
  };

  return (
    <div className="new-organisation">
      <a className="button button--purple" onClick={() => setModalOpen(true)}>
        Create
      </a>
      <RModal isOpen={isModalOpen} onRequestClose={handleClose}>
        <form className="rmodal" onSubmit={handleSave}>
          <p className="rmodal__title">Create Organisation</p>
          <div className="input-wrapper">
            <span className="input-carrets">{">>>"}</span>
            <input className="input" autoFocus={true} name="title" id="title" onChange={handleChangeTitle} value={title} placeholder="Title" />
            <span className="input-carrets">{"<<<"}</span>
          </div>
          <div className="input-wrapper">
            <span className="input-carrets">{">>>"}</span>
            <input className="input" name="shortTitle" id="shortTitle" onChange={handleChangeShortTitle} value={shortTitle} placeholder="Short title" />
            <span className="input-carrets">{"<<<"}</span>
          </div>
          <div className="rmodal-actions">
            <a className="button button--fuschia" onClick={handleClose}>
              Discard
            </a>
            <button className="button button--blue" type="submit">
              Save
            </button>
          </div>
        </form>
      </RModal>
    </div>
  );
}
