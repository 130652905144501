import { useState } from "react";
import RModal from "../Modal";
import Settings from "../Settings";
import LeftColumn from "./LeftColumn";
import "./styles.scss";

export default function Header() {
  const [settingsStatus, setSettingsStatus] = useState("");
  const [isModalOpened, setModalOpened] = useState(false);

  const openModal = () => {
    setSettingsStatus("opened");
    setModalOpened(true);
  };

  const closeModal = () => {
    setSettingsStatus("closed");
    setModalOpened(false);
  };

  return (
    <>
      <div className="header">
        <div className="header__column header__column--left">
          <LeftColumn />
        </div>
        <div className="header__column header__column--center">
          <a href="/" className="header__logo-title">
            <div className="header__image-wrapper">
              <div className="header__image" />
            </div>
            <div className="header__text">
              <span className="">P</span>
              <span className="yellow-text">o</span>
              <span className="">k</span>
              <span className="green-text">er</span>
              <span className="">P</span>
              <span className="">l</span>
              <span className="yellow-text">an</span>
              <span className="">n</span>
              <span className="purple-text">er</span>
            </div>
          </a>
        </div>
        <div className="header__column header__column--right">
          <p onClick={openModal} className="button header__settings-title">
            Settings
          </p>
          <div className="header__settings">
            <div onClick={openModal} className={"gear " + settingsStatus}>
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
              <div className="circle"></div>
            </div>
          </div>
        </div>
      </div>
      <RModal isOpen={isModalOpened} onRequestClose={closeModal}>
        <Settings onClose={closeModal} />
      </RModal>
    </>
  );
}
