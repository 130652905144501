import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { organisationsFromStore } from "../../../redux/applicationAdminDashboardReducer/selectors";
import { getAllOrganisations } from "../../../redux/applicationAdminDashboardReducer";
import RModal from "../Modal";
import RemoveOrganisation from "../RemoveOrganisation";
import EditOrganisation from "../EditOrganisation";
import Pagination from "../Pagination";
import { organsiationsCountFromStore } from "../../../redux/applicationAdminDashboardReducer/selectors";
import "./styles.scss";

export default function OrganisationsList() {
  const organisations = useAppSelector(organisationsFromStore);
  const organisationCount = useAppSelector(organsiationsCountFromStore);
  const dispatch = useAppDispatch();

  const [activeOrganisationId, setActiveOrganisationId] = useState("");
  const [editOrganisationId, setEditOrganisationId] = useState("");
  const [removeOrganisationId, setRemoveOrganisationId] = useState("");
  let [searchParams, setSearchParams] = useSearchParams();
  const page = parseInt(searchParams.get("page") || "0");

  useEffect(() => {
    dispatch(getAllOrganisations(page));
  }, [searchParams, organisationCount]);

  const handleSelect = (id: string) => {
    if (activeOrganisationId && activeOrganisationId === id) {
      setActiveOrganisationId("");
    } else {
      setActiveOrganisationId(id);
    }
  };

  const renderOrganisation = () => {
    return organisations.slice(0, 8).map(({ title, _id }) => {
      return (
        <div className="organisation" key={_id} onClick={() => handleSelect(_id)}>
          <div className="organisation__meta">
            <p className="organisation__title">{title}</p>
          </div>
          <div className={`organisation__actions ${_id === activeOrganisationId ? "organisation__actions--active" : ""}`}>
            <Link to={`/organisation/${_id}`} className="button button--blue">
              Open
            </Link>
            <a className="button button--green" onClick={() => setEditOrganisationId(_id)}>
              Edit
            </a>
            <a className="button button--orange" onClick={() => setRemoveOrganisationId(_id)}>
              Remove
            </a>
          </div>
        </div>
      );
    });
  };

  const renderRemoveOrganisation = () => {
    if (!removeOrganisationId) {
      return;
    }

    const organisation = organisations.find(({ _id }) => _id === removeOrganisationId);

    if (!organisation) {
      return;
    }

    return (
      <RModal isOpen={true} onRequestClose={() => setRemoveOrganisationId("")}>
        <RemoveOrganisation organisation={organisation} closeModal={() => setRemoveOrganisationId("")} />
      </RModal>
    );
  };

  const renderEditOrganisation = () => {
    if (!editOrganisationId) {
      return;
    }
    const organisation = organisations.find(({ _id }) => _id === editOrganisationId);

    if (!organisation) {
      return;
    }
    return (
      <RModal isOpen={true} onRequestClose={() => setEditOrganisationId("")}>
        <EditOrganisation organisation={organisation} discard={() => setEditOrganisationId("")} />
      </RModal>
    );
  };

  const renderPagination = () => {
    const totalPages = Math.ceil(organisationCount / 8);
    return <Pagination totalPages={totalPages} link={`/organisations`} />;
  };

  return (
    <>
      <div className="organisation-container">{renderOrganisation()}</div>
      {renderRemoveOrganisation()}
      {renderEditOrganisation()}
      {renderPagination()}
    </>
  );
}
