import EstimationActions from "./EstimationActions";
import EstimationMetadata from "./EstimationMetadata";
import EstimationCards from "./EstimationsCards";
import "./styles.scss";

export default function EstimationComponent() {
  return (
    <div className="estimation-component">
      <EstimationMetadata />
      <EstimationCards />
      <EstimationActions />
    </div>
  );
}
