import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addAutoRemovableNotification } from "../../redux/notificationsReducer";
import axios from "axios";

export interface Session {
  title: string;
  adminId: string;
  dateCreated: string;
  expireDate?: string;
  token?: string;
  _id: string;
}

export interface DashboardReducerState {
  sessions: Session[];
  count: number;
}

const initialState: DashboardReducerState = {
  sessions: [],
  count: 0
};

export const getAllSessions = createAsyncThunk("dashboard/getAllSessions", async (page: number) => {
  const response = await axios.get(`/api/sessions?page=${page}`);
  return response.data as { sessions: Session[]; totalCount: number };
});

export const createSession = createAsyncThunk("dashboard/createSession", async (sessionTitle: string, { dispatch }) => {
  try {
    const response = await axios.post(`/api/sessions`, { title: sessionTitle });
    dispatch(
      addAutoRemovableNotification({
        message: "Created a session",
        type: "success"
      })
    );
    return response.data as Session;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const cause = e.response?.data || e.message;
      dispatch(
        addAutoRemovableNotification({
          message: `Not able to create a session: ${cause}`,
          type: "error"
        })
      );
    }

    throw new Error();
  }
});

export const removeSession = createAsyncThunk("dashboard/removeSession", async (id: string, { dispatch }) => {
  try {
    await axios.delete(`/api/sessions/${id}`);
    dispatch(
      addAutoRemovableNotification({
        message: "Session is removed",
        type: "success"
      })
    );
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const cause = e.response?.data || e.message;
      dispatch(
        addAutoRemovableNotification({
          message: `Not able to remove a session: ${cause}`,
          type: "error"
        })
      );
    }
    throw new Error();
  }
  return id;
});

export const updateExpirationToken = createAsyncThunk("dashboard/updateToken", async (payload: { sessionId: string; days: number }, { dispatch }) => {
  try {
    const response = await axios.patch(`/api/sessions/tokens/${payload.sessionId}`, { days: payload.days });
    dispatch(
      addAutoRemovableNotification({
        message: "Session is updated",
        type: "success"
      })
    );
    return response.data as Session;
  } catch (e) {
    dispatch(
      addAutoRemovableNotification({
        message: "Not able to update a session",
        type: "error"
      })
    );
    throw new Error();
  }
});

export const removeExpirationToken = createAsyncThunk("dashboard/removeToken", async (id: string, { dispatch }) => {
  try {
    const response = await axios.delete(`/api/sessions/tokens/${id}`);
    dispatch(
      addAutoRemovableNotification({
        message: "Session is public",
        type: "success"
      })
    );
    return response.data as Session;
  } catch (e) {
    dispatch(
      addAutoRemovableNotification({
        message: "Not able to make session public",
        type: "error"
      })
    );
    throw new Error();
  }
});

export const updateSession = createAsyncThunk("dashboard/updateSession", async (session: { id: string; title: string }, { dispatch }) => {
  const { title, id } = session;
  try {
    const response = await axios.patch(`/api/sessions/${id}`, { title });
    dispatch(
      addAutoRemovableNotification({
        message: "Session is updated",
        type: "success"
      })
    );
    return response.data as Session;
  } catch (e) {
    dispatch(
      addAutoRemovableNotification({
        message: "Not able to update a session",
        type: "error"
      })
    );
    throw new Error();
  }
});

export const dashBoardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllSessions.fulfilled, (state, action) => {
      state.sessions = action.payload.sessions;
      state.count = action.payload.totalCount;
    });
    builder.addCase(createSession.fulfilled, (state, action) => {
      state.sessions.unshift(action.payload);
      state.count = state.count + 1;
    });
    builder.addCase(removeSession.fulfilled, (state, action) => {
      state.sessions = state.sessions.filter(({ _id }) => _id !== action.payload);
      state.count = state.count - 1;
    });
    builder.addCase(updateSession.fulfilled, (state, action) => {
      state.sessions = state.sessions.filter(({ _id }) => _id !== action.payload._id).concat(action.payload);
    });
    builder.addCase(updateExpirationToken.fulfilled, (state, action) => {
      state.sessions = state.sessions.filter(({ _id }) => _id !== action.payload._id).concat(action.payload);
    });
    builder.addCase(removeExpirationToken.fulfilled, (state, action) => {
      state.sessions = state.sessions.filter(({ _id }) => _id !== action.payload._id).concat(action.payload);
    });
  }
});

export default dashBoardSlice.reducer;
