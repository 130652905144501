import { useEffect } from "react";
import "./styles.scss";
import { getAllRooms } from "../../../redux/admindashboardReducer/admindashboardRedux";
import { useAppSelector } from "../../../redux/hooks";
import { roomFromStore } from "../../../redux/admindashboardReducer/selectors";
import { useAppDispatch } from "../../../redux";

export default function RoomList() {
  const room = useAppSelector(roomFromStore);
  const dispatch = useAppDispatch();

  useEffect(() => {
    let timer = setTimeout(function tick() {
      dispatch(getAllRooms());
      timer = setTimeout(tick, 1000);
    }, 1000);
    return function () {
      clearTimeout(timer);
    };
  }, []);

  const renderUsers = (userNames: string[], roomId: string) => {
    return userNames.map((userName) => (
      <span key={`${userName}-${roomId}`} className="connected-users__userscount">
        {userName}
      </span>
    ));
  };
  const renderRoom = () => {
    if (!room.length) {
      return <span>No rooms for now</span>;
    }
    return room.map(({ roomId, userNames }) => {
      return (
        <div key={roomId} className="connected-users__content">
          <span className="connected-users__roomcount">{roomId}:</span>
          <div>{renderUsers(userNames, roomId)}</div>
        </div>
      );
    });
  };
  return <div className="connected-users">{renderRoom()}</div>;
}
