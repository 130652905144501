import reactStringReplace from "react-string-replace";
import { SessionRoom } from "../redux/sessionRoomReducer";

export const getSelectedStory = (session?: SessionRoom, selectedStoryId?: string) => {
  const storyId = selectedStoryId === session?.activeStoryId || !selectedStoryId ? session?.activeStoryId : selectedStoryId;
  return session?.stories.find((story) => story.id === storyId);
};

export const replaceTextToLinks = (text: string) => {
  const urlRegexp = /(https?:\/\/[^\s]+)/g;
  return reactStringReplace(text, urlRegexp, (match) => {
    return (
      <a target="_blank" href={match}>
        {getLastPartOfUrl(match)}
      </a>
    );
  });
};

const getLastPartOfUrl = (url: string) => {
  return url.substring(url.lastIndexOf("/") + 1, url.length);
};
