import React, { useState } from "react";
import { useAppDispatch } from "../../../redux";
import { useParams } from "react-router-dom";
import { createAdmin } from "../../../redux/organisationPageReducer";
import RModal from "../Modal";
import "./index.scss";

interface NewAdminProps {
  userType: string;
  title: string;
}

export default function NewAdmin(props: NewAdminProps) {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);

  const { id } = useParams();

  const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
    const regExp = /^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/;
    e.preventDefault();
    if ((!email && !props.userType) || !regExp.test(email)) {
      return;
    }
    dispatch(createAdmin({ email, type: props.userType, organisationId: id }));
    handleClose();
  };

  const handleClose = () => {
    setModalOpen(false);
    setEmail("");
  };

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue = e.target.value;
    if (emailValue) {
      setEmail(emailValue);
    } else {
      setEmail("");
    }
  };

  return (
    <div className="new-admin">
      <div className="new-admin__add">
        <a className="button button--purple" onClick={() => setModalOpen(true)}>
          Add
        </a>
      </div>
      <RModal isOpen={isModalOpen} onRequestClose={handleClose}>
        <form className="rmodal" onSubmit={handleSave}>
          <p className="rmodal__title">{props.title}</p>
          <div className="input-wrapper">
            <span className="input-carrets">{">>>"}</span>
            <input className="input" autoFocus={true} name="email" id="email" onChange={handleChangeEmail} value={email} placeholder="Email" />
            <span className="input-carrets">{"<<<"}</span>
          </div>
          <div className="rmodal-actions">
            <a className="button button--fuschia" onClick={handleClose}>
              Discard
            </a>
            <button className="button button--blue" type="submit">
              Save
            </button>
          </div>
        </form>
      </RModal>
    </div>
  );
}
