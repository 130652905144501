import React from "react";
import { removeAdmin, Admin } from "../../../redux/organisationPageReducer";
import { useAppDispatch } from "../../../redux";

interface RemoveAdminProps {
  closeModal: () => void;
  admin: Admin;
}

export default function RemoveAdmin(props: RemoveAdminProps) {
  const dispatch = useAppDispatch();

  const removeHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(removeAdmin({ id: props.admin._id, organisationId: "" }));
    props.closeModal();
  };

  return (
    <form className="rmodal" onSubmit={removeHandler}>
      <p className="rmodal__title">Delete Administrator</p>
      <div className="input-wrapper">
        <span className="input-carrets">{">>>"}</span>
        <input className="input" disabled value={props.admin.email} />
        <span className="input-carrets">{"<<<"}</span>
      </div>
      <div className="rmodal-actions">
        <a className="button button--fuschia" onClick={props.closeModal}>
          No
        </a>
        <button type="submit" className="button button--blue" autoFocus={true}>
          Yes
        </button>
      </div>
    </form>
  );
}
