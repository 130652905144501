import "./index.scss";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useParams } from "react-router-dom";
import { adminsFromStore } from "../../../redux/organisationPageReducer/selectors";
import { organisationsFromStore } from "../../../redux/applicationAdminDashboardReducer/selectors";
import { storyUrlMappingFromStore } from "../../../redux/storyUrlMappingReducer/selectors";
import { getAdmins } from "../../../redux/organisationPageReducer";
import { getOrganisation } from "../../../redux/applicationAdminDashboardReducer";
import { getStoryUrlMappings } from "../../../redux/storyUrlMappingReducer";
import RemoveAdmin from "../../content/RemoveAdmin";
import RemoveStoryUrlMapping from "../../content/RemoveStoryUrlMapping";
import NewStoryUrlMapping from "../../content/NewStoryUrlMapping";
import NewAdmin from "../../content/NewAdmin";
import EditStoryUrlMapping from "../../content/EditStoryUrlMapping";
import EditOrganisation from "../../content/EditOrganisation";
import RModal from "../../content/Modal";

export default function OrganisationAdmin() {
  const dispatch = useAppDispatch();
  const organisationAdmins = useAppSelector(adminsFromStore);
  const organisations = useAppSelector(organisationsFromStore);
  const storyUrlMappings = useAppSelector(storyUrlMappingFromStore);

  const { id } = useParams();

  const [activeId, setActiveId] = useState("");
  const [removeAdminId, setRemoveAdminId] = useState("");

  const [editStoryUrlMappingId, setEditStoryUrlMappingId] = useState("");
  const [removeStoryUrlMappingId, setRemoveStoryUrlMappingId] = useState("");
  const [editOrganisationId, setEditOrganisationId] = useState("");

  useEffect(() => {
    dispatch(getOrganisation(id));
    dispatch(getAdmins(id));
    dispatch(getStoryUrlMappings(id));
  }, []);

  const handleSelect = (id: string) => {
    if (activeId && activeId === id) {
      setActiveId("");
    } else {
      setActiveId(id);
    }
  };

  const storyMapping = () => {
    return storyUrlMappings.map(({ storyNaming, prefixUrl, _id }) => {
      return (
        <div key={_id} className="administrator__mapping" onClick={() => handleSelect(_id)}>
          <div className="administrator__story-mapping">
            <p className="administrator__story-naming">{storyNaming}</p>
          </div>
          <div className="administrator__story-mapping">
            <p className={`administrator__prefix-url ${_id == activeId ? "administrator__prefix-url--active" : ""}`}>{prefixUrl}</p>
          </div>
          <div className={`administrator__actions ${_id === activeId ? "administrator__actions--active" : ""}`}>
            <a className="button button--green" onClick={() => setEditStoryUrlMappingId(_id)}>
              Edit
            </a>
            <a className="button button--orange" onClick={() => setRemoveStoryUrlMappingId(_id)}>
              Remove
            </a>
          </div>
        </div>
      );
    });
  };

  const organisationAdmin = () => {
    return organisationAdmins.admins.map(({ email, _id, type }) => {
      if (type === "organisation") {
        return (
          <div key={_id} className="administrator__admins" onClick={() => handleSelect(_id)}>
            <div className="administrator__organisation-admins">
              <p className="administrator__email">{email}</p>
            </div>
            <div className={`administrator__actions ${_id === activeId ? "administrator__actions--active" : ""}`}>
              <a className="button button--orange" onClick={() => setRemoveAdminId(_id)}>
                Remove
              </a>
            </div>
          </div>
        );
      }
    });
  };

  const sessionAdmin = () => {
    return organisationAdmins.admins.map(({ email, _id, type }) => {
      if (type === "session") {
        return (
          <div key={_id} className="administrator__admins" onClick={() => handleSelect(_id)}>
            <div className="administrator__session-admins">
              <p className="administrator__email">{email}</p>
            </div>
            <div className={`administrator__actions ${_id === activeId ? "administrator__actions--active" : ""}`}>
              <a className="button button--orange" onClick={() => setRemoveAdminId(_id)}>
                Remove
              </a>
            </div>
          </div>
        );
      }
    });
  };

  const renderRemoveAdmin = () => {
    if (!removeAdminId) {
      return;
    }

    const organisationAdmin = organisationAdmins.admins.find(({ _id }) => _id === removeAdminId);

    if (!organisationAdmin) {
      return;
    }
    return (
      <RModal isOpen={true} onRequestClose={() => setRemoveAdminId("")}>
        <RemoveAdmin admin={organisationAdmin} closeModal={() => setRemoveAdminId("")} />
      </RModal>
    );
  };

  const renderRemoveStoryUrlMapping = () => {
    if (!removeStoryUrlMappingId) {
      return;
    }

    const storyUrlMapping = storyUrlMappings.find(({ _id }) => _id === removeStoryUrlMappingId);

    if (!storyUrlMapping) {
      return;
    }

    return (
      <RModal isOpen={true} onRequestClose={() => setRemoveStoryUrlMappingId("")}>
        <RemoveStoryUrlMapping storyUrlMapping={storyUrlMapping} closeModal={() => setRemoveStoryUrlMappingId("")} />
      </RModal>
    );
  };

  const renderEditStoryUrlMapping = () => {
    if (!editStoryUrlMappingId) {
      return;
    }

    const storyUrlMapping = storyUrlMappings.find(({ _id }) => _id === editStoryUrlMappingId);

    if (!storyUrlMapping) {
      return;
    }

    return (
      <RModal isOpen={true} onRequestClose={() => setEditStoryUrlMappingId("")}>
        <EditStoryUrlMapping storyUrlMapping={storyUrlMapping} discard={() => setEditStoryUrlMappingId("")} />
      </RModal>
    );
  };

  const renderEditOrganisation = () => {
    if (!editOrganisationId) {
      return;
    }

    const organisation = organisations.find(({ _id }) => _id === editOrganisationId);

    if (!organisation) {
      return;
    }

    return (
      <RModal isOpen={true} onRequestClose={() => setEditOrganisationId("")}>
        <EditOrganisation organisation={organisation} discard={() => setEditOrganisationId("")} />
      </RModal>
    );
  };

  const renderMainContent = () => {
    const organisation = organisations.find(({ _id }) => _id === id);
    if (!organisation) {
      return;
    }
    return (
      <div className="administrator">
        <div className="administrator__meta">
          <p className="administrator__title">{organisation.title}</p>
          <a className="button" onClick={() => setEditOrganisationId(organisation._id)}>
            Change
          </a>
        </div>
        <div className="administrator__organisation">
          <p className="administrator__admins-title">Story mapping</p>
          {storyMapping()}
          <NewStoryUrlMapping />
        </div>
        <div className="administrator__organisation">
          <p className="administrator__admins-title">Organisation Administrators</p>
          {organisationAdmin()}
          <NewAdmin userType="organisation" title="Enter Organisation Admin Email" />
        </div>
        <div className="administrator__session">
          <p className="administrator__admins-title">Session Administrators</p>
          {sessionAdmin()}
          <NewAdmin userType="session" title="Enter Session Admin Email" />
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="administrator-container">
        {renderMainContent()}
        {renderEditOrganisation()}
        {renderRemoveAdmin()}
        {renderRemoveStoryUrlMapping()}
        {renderEditStoryUrlMapping()}
      </div>
    </>
  );
}
