import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addAutoRemovableNotification } from "../notificationsReducer";

export interface Backup {
  time: string;
}

export interface BackupsReducerState {
  backups: Backup[];
}

const initialState: BackupsReducerState = {
  backups: []
};

export const getAllBackups = createAsyncThunk("backups/getAllBackups", async (params, { dispatch }) => {
  try {
    const response = await axios.get("/api/backups");
    return response.data as Backup[];
  } catch (error) {
    if (axios.isAxiosError(error))
      dispatch(
        addAutoRemovableNotification({
          message: `Not able to get data: ${error.message}`,
          type: "error"
        })
      );
    throw new Error();
  }
});

export const createBackup = createAsyncThunk("backups/createBackup", async (params, { dispatch }) => {
  try {
    const response = await axios.post("/api/backups");
    dispatch(
      addAutoRemovableNotification({
        message: "Created a backup",
        type: "success"
      })
    );
    return response.data as Backup;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const cause = e.response?.data || e.message;
      dispatch(
        addAutoRemovableNotification({
          message: `Not able to create data: ${cause}`,
          type: "error"
        })
      );
    }
    throw new Error();
  }
});

export const removeBackup = createAsyncThunk("backups/removeBackup", async (time: string, { dispatch }) => {
  try {
    await axios.delete(`/api/backups/${time}`);
    dispatch(
      addAutoRemovableNotification({
        message: "Backup is removed",
        type: "success"
      })
    );
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const cause = e.response?.data || e.message;
      dispatch(
        addAutoRemovableNotification({
          message: `Not able to remove a backup: ${cause}`,
          type: "error"
        })
      );
    }
    throw new Error();
  }
  return time;
});

export const restoreBackup = createAsyncThunk("backups/restoreBackup", async (time: string, { dispatch }) => {
  try {
    const response = await axios.get(`/api/backups/${time}`);
    const status = response.data as string[];
    dispatch(
      addAutoRemovableNotification({
        message: "Backup is restored for the following documents: " + status.join(", "),
        type: "success"
      })
    );
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const cause = e.response?.data || e.message;
      dispatch(
        addAutoRemovableNotification({
          message: `${cause}`,
          type: "error"
        })
      );
    }
    throw new Error();
  }
});

const backupSlice = createSlice({
  name: "backup",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getAllBackups.fulfilled, (state, action) => {
      state.backups = action.payload;
    });
    builder.addCase(createBackup.fulfilled, (state, action) => {
      state.backups.push(action.payload);
    });
    builder.addCase(removeBackup.fulfilled, (state, action) => {
      state.backups = state.backups.filter(({ time }) => time !== action.payload);
    });
  }
});

export default backupSlice.reducer;
