import { useState } from "react";
import { RootState } from "../../../redux";
import { useAppSelector } from "../../../redux/hooks";
import { sessionRoomSelector } from "../../../redux/sessionRoomReducer/selectors";
import StoryListComponent from "../StoryList";

export default function LeftColumn() {
  const session = useAppSelector(sessionRoomSelector);
  const [menuStatus, setMenuStatus] = useState("");
  const { isAdmin } = useAppSelector((state: RootState) => state.userReducer);

  const handleClickMenu = () => {
    setMenuStatus(menuStatus !== "opened" ? "opened" : "closed");
  };

  const getDashboardLink = () => {
    if (!isAdmin) {
      return null;
    }
    return (
      <a href="/dashboard" className="button button--orange">
        Dashboard
      </a>
    );
  };

  const getStoriesSection = () => {
    if (session) {
      return <StoryListComponent closeMenuHandler={() => setMenuStatus("closed")}/>;
    }
    return null;
  };

  return (
    <div className="header-left-column">
      <div onClick={handleClickMenu} className={"header-left-column__menu " + menuStatus}>
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </div>
      <div className={"header-left-column__content " + menuStatus}>
        <div className="header-left-column__links">{getDashboardLink()}</div>
        <div className="header-left-column__stories">
          {getStoriesSection()}
        </div>
      </div>
    </div>
  );
}
