import { useState } from "react";
import { useAppSelector } from "../../../redux/hooks";
import { sessionRoomSelector } from "../../../redux/sessionRoomReducer/selectors";
import { userIdSelector } from "../../../redux/userReducer/selectors";
import { endVoting, startVoting } from "../../../services/socketsHandler";
import { getSelectedStory } from "../../../utils/sessionUtils";
import FinalVote from "../FinalVote";
import RModal from "../Modal";

export default function EstimationActionsComponent() {
  const [isModalOpen, setModalOpen] = useState(false);
  const userId = useAppSelector(userIdSelector);
  const session = useAppSelector(sessionRoomSelector);

  const isStartVotingShown = () => {
    if (session?.stories.find((story) => story.status === "progress")) {
      return false;
    }
    const activeStoryId = session?.activeStoryId;
    if (!activeStoryId) {
      return false;
    }
    const story = session.stories.find((someStory) => someStory.id === activeStoryId);
    if (!story) {
      return false;
    }
    return story.status !== "progress";
  };

  const isEndVotingShown = () => {
    const activeStoryId = session?.activeStoryId;
    if (!activeStoryId) {
      return false;
    }
    const story = session.stories.find((someStory) => someStory.id === activeStoryId);
    if (!story) {
      return false;
    }
    return story.status === "progress";
  };

  const getStartVotingLabel = () => {
    const selecteStory = getSelectedStory(session);

    if (selecteStory?.status === "voted") {
      return "Revote";
    }

    return "Start Voting";
  };

  const handleStartVoting = () => {
    startVoting();
  };

  const handleEndVoting = () => {
    endVoting();
  };

  const getActiveStoryFinalVote = () => {
    if (session?.activeStoryId) {
      return session.stories.find((story) => story.id === session.activeStoryId)?.finalVote;
    }
  };

  const renderStartVotingCta = () => {
    if (!isStartVotingShown()) {
      return null;
    }
    return <button onClick={handleStartVoting}>{getStartVotingLabel()}</button>;
  };

  const renderEndVotingCta = () => {
    if (!isEndVotingShown()) {
      return;
    }
    return (
      <button onClick={handleEndVoting} hidden={!isEndVotingShown()}>
        End Voting
      </button>
    );
  };

  const renderFinalVoteCta = () => {
    if (getSelectedStory(session)?.status !== "voted") {
      return;
    }
    return <button onClick={() => setModalOpen(true)}>Final Vote</button>;
  };

  const getContent = () => {
    if (!userId || userId !== session?.adminId) {
      return null;
    }
    return (
      <div className="estimation-actions-component">
        {renderStartVotingCta()}
        {renderEndVotingCta()}
        {renderFinalVoteCta()}
        <RModal isOpen={isModalOpen} onRequestClose={() => setModalOpen(false)}>
          <FinalVote finalVote={getActiveStoryFinalVote()} closeModal={() => setModalOpen(false)} />
        </RModal>
      </div>
    );
  };

  return getContent();
}
