import React, { useState } from "react";
import { useAppDispatch } from "../../../redux";
import { useParams } from "react-router-dom";
import { createStoryUrlMapping } from "../../../redux/storyUrlMappingReducer";
import RModal from "../Modal";

export default function NewStoryUrlMapping() {
  const dispatch = useAppDispatch();

  const [storyNaming, setStoryNaming] = useState("");
  const [prefixUrl, setPrefixUrl] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);

  const { id } = useParams();

  const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!storyNaming && !prefixUrl) {
      return;
    }
    dispatch(createStoryUrlMapping({ storyNaming, organisationId: id, prefixUrl }));
    handleClose();
  };

  const handleClose = () => {
    setModalOpen(false);
    setStoryNaming("");
    setPrefixUrl("");
  };

  const handleChangeStoryNaming = (e: React.ChangeEvent<HTMLInputElement>) => {
    const storyNamingValue = e.target.value;
    if (storyNamingValue) {
      setStoryNaming(storyNamingValue);
    } else {
      setStoryNaming("");
    }
  };

  const handleChangePrefixUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
    const prefixUrlValue = e.target.value;
    if (prefixUrlValue) {
      setPrefixUrl(prefixUrlValue);
    } else {
      setPrefixUrl("");
    }
  };

  return (
    <div className="new-admin">
      <div className="new-admin__add">
        <a className="button button--purple" onClick={() => setModalOpen(true)}>
          Add
        </a>
      </div>
      <RModal isOpen={isModalOpen} onRequestClose={handleClose}>
        <form className="rmodal" onSubmit={handleSave}>
          <p className="rmodal__title">Enter Story Mapping</p>
          <div className="input-wrapper">
            <span className="input-carrets">{">>>"}</span>
            <input className="input" autoFocus={true} name="storyNaming" id="storyNaming" onChange={handleChangeStoryNaming} value={storyNaming} placeholder="Story Naming" />
            <span className="input-carrets">{"<<<"}</span>
          </div>
          <div className="input-wrapper">
            <span className="input-carrets">{">>>"}</span>
            <input className="input" name="prefixUrl" id="prefixUrl" onChange={handleChangePrefixUrl} value={prefixUrl} placeholder="Prefix Url" />
            <span className="input-carrets">{"<<<"}</span>
          </div>
          <div className="rmodal-actions">
            <a className="button button--fuschia" onClick={handleClose}>
              Discard
            </a>
            <button className="button button--blue" type="submit">
              Save
            </button>
          </div>
        </form>
      </RModal>
    </div>
  );
}
