import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getCookie, setCookie } from "../../utils/cookieUtils";

export interface UserReducerState {
  userName: string | null;
  userId: string | null;
  selectedStory: string;
  storyViewMode: string | null;
  isAdmin: boolean;
  isSystemAdmin: boolean;
  isOrganisationAdmin: boolean;
  organisationId: string | null;
}

export interface User {
  _id: string;
  fullName: string;
  type: string;
  organisationId: string;
}

const initialState: UserReducerState = {
  userName: getCookie("userName"),
  userId: getCookie("userId"),
  storyViewMode: getCookie("storyViewMode"),
  selectedStory: "",
  isAdmin: false,
  isOrganisationAdmin: false,
  isSystemAdmin: false,
  organisationId: null
};

export const getCurrentUser = createAsyncThunk("dashboard/getCurrentUser", async () => {
  const response = await axios.get(`/api/auth/current-user`);
  return response.data as User;
});

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserName: (state, action: PayloadAction<string>) => {
      const userId = state.userId || Math.random().toString(36).slice(2, 7) + Math.random().toString(36).slice(2, 7) + Math.random().toString(36).slice(2, 7);
      setCookie("userName", action.payload);
      setCookie("userId", userId);
      state.userName = action.payload;
      state.userId = userId;
    },
    setSelectedStory: (state, action: PayloadAction<string>) => {
      state.selectedStory = action.payload;
    },
    setStoryViewMode: (state, action: PayloadAction<string>) => {
      setCookie("storyViewMode", action.payload);
      state.storyViewMode = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getCurrentUser.fulfilled, (state, action) => {
      state.isAdmin = !!action.payload.type;
      state.isSystemAdmin = action.payload.type === "application";
      state.isOrganisationAdmin = action.payload.type === "organisation";
      state.organisationId = action.payload.organisationId;
      if (action.payload._id && action.payload.fullName) {
        state.userName = action.payload.fullName;
        state.userId = action.payload._id;
      }
    });
  }
});

export const { setUserName, setSelectedStory, setStoryViewMode } = userSlice.actions;

export default userSlice.reducer;
