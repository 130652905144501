import { RootState } from "../../../redux";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";
import UserNameModal from "../../content/UserNameModal";
import MainContent from "./MainContent";
import RModal from "../../content/Modal";
import "./style.scss";

export default function SessionPage() {
  const userName = useAppSelector((state: RootState) => state.userReducer.userName);
  const navigate = useNavigate();

  const redirectHandler = () => {
    navigate("/");
  };

  const getContent = () => {
    if (!userName) {
      return (
        <RModal isOpen={true} onRequestClose={redirectHandler}>
          <UserNameModal redirectHandler={redirectHandler} />
        </RModal>
      );
    }
    return <MainContent />;
  };

  return <div className="session-page">{getContent()}</div>;
}
