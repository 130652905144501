import { useState } from "react";
import { useAppDispatch } from "../../../redux";
import { useAppSelector } from "../../../redux/hooks";
import { sessionRoomSelector } from "../../../redux/sessionRoomReducer/selectors";
import { setSelectedStory, setStoryViewMode } from "../../../redux/userReducer";
import { storyViewModeSelector, userIdSelector } from "../../../redux/userReducer/selectors";
import { sortStory } from "../../../services/socketsHandler";
import AddStory from "../AddStory";
import Toggle from "../Toggle";
import Story from "./Story";

interface SessionListProps {
  closeMenuHandler?: () => void;
}

export default function StoryListComponent(props: SessionListProps) {
  const sessionRoom = useAppSelector(sessionRoomSelector);
  const storyViewMode = useAppSelector(storyViewModeSelector);
  const dispatch = useAppDispatch();
  const userId = useAppSelector(userIdSelector);
  const [hightlightModeIndicator, setHightlightModeIndicator] = useState("");

  const renderStories = () => {
    return sessionRoom?.stories?.map((story) => <Story story={story} handleClickWhenAutoModeEnabled={highlightModeButton} />);
  };
  const highlightModeButton = () => {
    setHightlightModeIndicator(hightlightModeIndicator !== "hightlited1" ? "hightlited1" : "hightlited2");
  };
  const handleSortClick = () => {
    if (sessionRoom && sessionRoom.id) {
      sortStory(sessionRoom?.id);
    }
  };

  const handleModeClick = () => {
    const newMode = storyViewMode === "manual" ? "auto" : "manual";
    if (newMode === "auto") {
      dispatch(setSelectedStory(""));
    }
    dispatch(setStoryViewMode(newMode));
  };

  const getStoryModeSection = () => {
    if (userId === sessionRoom?.adminId) {
      return null;
    }
    return (
      <div className={"story-list__story-view-mode " + hightlightModeIndicator}>
        <span className="story-list__navigation-text" onClick={handleModeClick}>
          Auto navigation
        </span>
        <Toggle checked={storyViewMode === "manual"} handleChange={handleModeClick} />
      </div>
    );
  };

  const getSortingStorySection = () => {
    if (!userId || userId !== sessionRoom?.adminId) {
      return null;
    }
    return (
      <a className="story-list__sorting-button" onClick={handleSortClick}>
        SORT
      </a>
    );
  };

  return (
    <div className="story-list">
      <div className={"story-list__content "}>
        <div className="story-list__head">
          Stories
          {getSortingStorySection()}
        </div>
        <div className="story-list__nav">{getStoryModeSection()}</div>
        <div className="story-list__stories" onClick={props.closeMenuHandler}>{renderStories()}</div>
        <AddStory />
      </div>
    </div>
  );
}
