import { removeOrganisation, Organisation } from "../../../redux/applicationAdminDashboardReducer";
import { useAppDispatch } from "../../../redux";
import React from "react";

interface RemoveOrganisationProps {
  closeModal: () => void;
  organisation: Organisation;
}

export default function RemoveOrganisation(props: RemoveOrganisationProps) {
  const dispatch = useAppDispatch();

  const removeHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(removeOrganisation(props.organisation._id));
    props.closeModal();
  };

  return (
    <form className="rmodal" onSubmit={removeHandler}>
      <p className="rmodal__title">Delete Organisation</p>
      <div className="input-wrapper">
        <span className="input-carrets">{">>>"}</span>
        <input className="input" disabled value={props.organisation.title} />
        <span className="input-carrets">{"<<<"}</span>
      </div>
      <div className="rmodal-actions">
        <a className="button button--fuschia" onClick={props.closeModal}>
          No
        </a>
        <button type="submit" className="button button--blue" autoFocus={true}>
          Yes
        </button>
      </div>
    </form>
  );
}
