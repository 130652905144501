import "./styles.scss";
import RoomList from "../../content/RoomList";

export default function SocketUsagePage() {
  return (
    <div className="dashboard-page">
      <p className="dashboard-page__title">Socket Usage Stats</p>
      <p>Socket Usage</p>
      <RoomList />
    </div>
  );
}
