import { useState } from "react";
import { updateExpirationToken, Session } from "../../../redux/dashboardReducer";
import { useAppDispatch } from "../../../redux";

interface SecureSessionProps {
  closeModal: () => void;
  session: Session;
}

export default function RotateSessionToken(props: SecureSessionProps) {
  const [daysValid, setDaysValid] = useState("1");
  const dispatch = useAppDispatch();

  const updateHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const days = parseInt(daysValid);
    if (!isNaN(days) && days >= 1 && days <= 365) {
      dispatch(updateExpirationToken({ sessionId: props.session._id, days }));
      props.closeModal();
    }
  };

  const getTitle = () => {
    if (props.session.token) {
      return <p className="rmodal__title">Update Token</p>;
    } else {
      return <p className="rmodal__title">Add Token</p>;
    }
  };

  const handleDaysChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const days = e.target.value;
    setDaysValid(days);
  };

  return (
    <form className="rmodal" onSubmit={updateHandler}>
      {getTitle()}
      <div className="input-wrapper">
        <span className="input-carrets">{">>>"}</span>
        <input className="input" disabled value={props.session.title} />
        <span className="input-carrets">{"<<<"}</span>
      </div>
      <div className="input-wrapper">
        <span className="input-carrets">{"Days valid: >>>"}</span>
        <input className="input number" type="number" min="0" max="365" value={daysValid} onChange={handleDaysChange} />
        <span className="input-carrets">{"<<<"}</span>
        <br></br>
      </div>
      <div className="rmodal-actions">
        <a className="button button--fuschia" onClick={props.closeModal}>
          No
        </a>
        <button type="submit" className="button button--blue" autoFocus={true}>
          Yes
        </button>
      </div>
    </form>
  );
}
