import SessionList from "../../content/SessionList";
import NewSession from "../../content/NewSession";
import "./index.scss";

export default function DashboardPage() {
  return (
    <div className="dashboard-page">
      <p className="dashboard-page__title">Dashboard</p>
      <SessionList />
      <NewSession />
    </div>
  );
}
