import { useState } from "react";
import { useAppSelector } from "../../../redux/hooks";
import { sessionRoomSelector } from "../../../redux/sessionRoomReducer/selectors";
import { addStory } from "../../../services/socketsHandler";
import { userIdSelector } from "../../../redux/userReducer/selectors";
import RModal from "../Modal";

export default function AddStorySection() {
  const session = useAppSelector(sessionRoomSelector);
  const userId = useAppSelector(userIdSelector);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleChangeTitle = (event: React.FormEvent<HTMLInputElement>) => {
    setTitle(event.currentTarget.value || "");
  };

  const handleChangeDescription = (event: React.FormEvent<HTMLInputElement>) => {
    setDescription(event.currentTarget.value || "");
  };

  const saveButtonClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    addStory(title, description);
    setTitle("");
    setDescription("");
    setIsModalOpen(false);
  };

  const closeButtonCLick = () => {
    setTitle("");
    setDescription("");
    setIsModalOpen(false);
  };

  const getContent = () => {
    if (!userId || userId !== session?.adminId) {
      return null;
    }
    return (
      <div className="add-story">
        <RModal isOpen={isModalOpen} onRequestClose={closeButtonCLick}>
          <form className="rmodal" onSubmit={saveButtonClick}>
            <p className="rmodal__title">Enter Story Details</p>
            <div className="input-wrapper">
              <span className="input-carrets">{">>>"}</span>
              <input className="input" autoFocus={true} value={title} onChange={handleChangeTitle} placeholder="Title" />
              <span className="input-carrets">{"<<<"}</span>
            </div>
            <div className="input-wrapper">
              <span className="input-carrets">{">>>"}</span>
              <input className="input" value={description} onChange={handleChangeDescription} placeholder="Description" />
              <span className="input-carrets">{"<<<"}</span>
            </div>
            <div className="rmodal-actions">
              <a className="button button--fuschia" onClick={closeButtonCLick}>
                Cancel
              </a>
              <button type="submit" className="button button--blue">
                Save
              </button>
            </div>
          </form>
        </RModal>
        <a className="button" onClick={() => setIsModalOpen(true)}>
          Create Story
        </a>
      </div>
    );
  };

  return getContent();
}
