import axios from "axios";
import { store } from "../redux";
import { SessionRoom } from "../redux/sessionRoomReducer";
import { addAutoRemovableNotification } from "../redux/notificationsReducer";

class SessionHelper {
  cloneSession = async (session: SessionRoom | undefined) => {
    if (!session) {
      this.handlerError();
      return;
    }
    try {
      const response = (await axios.post("/api/sessions", { ...session })) as { data: SessionRoom & { _id: string } };
      const _id = response.data._id;
      store.dispatch(
        addAutoRemovableNotification({
          message: `You will be navigated to cloned session automatically - ${_id}`,
          type: "success"
        })
      );
      this.handleRedirect(_id);
    } catch (e) {
      this.handlerError();
    }
  };

  private handlerError = () => {
    store.dispatch(
      addAutoRemovableNotification({
        message: "Not able to clone a session",
        type: "error"
      })
    );
  };

  private handleRedirect = (_id: string) => {
    setTimeout(() => {
      window.location.href = `/planningsession/${_id}`;
    }, 1500);
  };
}

export default new SessionHelper();
