import { useState } from "react";
import { useAppSelector } from "../../../redux/hooks";
import { sessionRoomSelector } from "../../../redux/sessionRoomReducer/selectors";
import { selectedStorySelector, userSelector } from "../../../redux/userReducer/selectors";
import { vote } from "../../../services/socketsHandler";
import { getSelectedStory } from "../../../utils/sessionUtils";

export default function EstimationCardsComponent() {
  const selectedStoryId = useAppSelector(selectedStorySelector);
  const { userId, isAdmin } = useAppSelector(userSelector);
  const session = useAppSelector(sessionRoomSelector);
  const [isCardsShown, setCardsShown] = useState(true);

  const numbers = ["1", "2", "3", "5", "8", "13"];
  const colors = ["green", "yellow", "blue", "purple", "orange", "fuschia"];
  let colorIndex = 0;

  const getColor = () => {
    const color = colors[colorIndex];
    colorIndex++;
    if (colorIndex >= colors.length) {
      colorIndex = 0;
    }
    return color;
  };

  const handleCardClick = (number: string) => {
    const selectedStory = getSelectedStory(session, selectedStoryId);
    const isSelectedStoryActive = selectedStory?.id === session?.activeStoryId;
    const isCurrentStoryVoting = isSelectedStoryActive && selectedStory?.status === "progress";
    if (isCurrentStoryVoting) {
      vote(number);
    }
  };

  const isVotedByCurrentUser = (number: string) => {
    const selectedStory = getSelectedStory(session, selectedStoryId);
    const isCurrentStoryVoting = selectedStory?.status === "progress";
    if (isCurrentStoryVoting) {
      return !!selectedStory.currentVoting.find((vote) => vote.userId === userId && vote.estimation === number);
    }

    const isCurrentStoryVoted = selectedStory?.status === "voted";

    if (isCurrentStoryVoted) {
      return !!selectedStory.votes.find((vote) => vote.userId === userId && vote.estimation === number);
    }

    return false;
  };

  const getNumber = (displayNumber: string, number: string, color: string, className?: string) => {
    className = className ? className : "";
    const isActive = isVotedByCurrentUser(number);
    if (isActive) {
      className += " estimation-cards__card--active";
    }
    return (
      <div key={number} onClick={() => handleCardClick(number)} className={`estimation-cards__card ${color}-text ${className}`}>
        <span className="estimation-cards__card-value">{displayNumber}</span>
      </div>
    );
  };

  const getNumbers = () => {
    return numbers.map((number) => getNumber(number, number, getColor()));
  };

  const getCardsToggleButton = () => {
    if (!isAdmin) {
      return;
    }
    return (
      <button onClick={() => setCardsShown(!isCardsShown)} className="toggle-cards button button--blue">
        {isCardsShown ? "Hide cards" : "Show cards"}
      </button>
    );
  };

  const renderOverlay = () => {
    const selectedStory = getSelectedStory(session, selectedStoryId);
    const isSelectedStoryActive = selectedStory?.id === session?.activeStoryId;
    const isCurrentStoryVoting = isSelectedStoryActive && selectedStory?.status === "progress";

    if (isCurrentStoryVoting) {
      return null;
    }

    return (
      <>
        <div className="estimation-cards__overlay"></div>
        <p className="estimation-cards__overlay-warning">Estimation for this story is not active</p>
      </>
    );
  };

  return (
    <>
      {getCardsToggleButton()}
      <div hidden={!isCardsShown} className="estimation-cards">
        {getNumbers()}
        {getNumber("100\n500", "100500", getColor(), "low-font-size")}
        {getNumber("skip", "skip", getColor(), "low-font-size")}
        {renderOverlay()}
      </div>
    </>
  );
}
