import { useAppSelector } from "../../../redux/hooks";
import { notificationSelector } from "../../../redux/notificationsReducer/selector";
import { deleteNotification } from "../../../redux/notificationsReducer";
import { useAppDispatch } from "../../../redux";
import "./index.scss";

export default function NotificationList() {
  const notifications = useAppSelector(notificationSelector);
  const dispatch = useAppDispatch();

  const renderNotifications = () => {
    if (!notifications.length) {
      return null;
    }

    return notifications.map(({ message, type, id }) => {
      return (
        <div className={`notifications__item notifications__item--${type}`} key={id}>
          <p className="notifications__text">{message}</p>
          <span className="notifications__close button" onClick={() => dispatch(deleteNotification(id))}>
            Close
          </span>
        </div>
      );
    });
  };

  return <div className="notifications">{renderNotifications()}</div>;
}
