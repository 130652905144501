import { useState } from "react";
import { Story } from "../../../redux/sessionRoomReducer";
import { editStory } from "../../../services/socketsHandler";

interface EditStoryProps {
  story: Story;
  closeModal: () => void;
}

export default function EditStory(props: EditStoryProps) {
  const [title, setTitle] = useState(props.story.title);
  const [description, setDescription] = useState(props.story.description);

  const handleChangeTitle = (event: React.FormEvent<HTMLInputElement>) => {
    setTitle(event.currentTarget.value);
  };

  const handleChangeDescription = (event: React.FormEvent<HTMLInputElement>) => {
    setDescription(event.currentTarget.value);
  };

  const saveChanges = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    editStory(props.story.id, title, description);
    props.closeModal();
  };

  return (
    <form className="rmodal" onSubmit={saveChanges}>
      <p className="rmodal__title">Edit Story</p>
      <div className="input-wrapper">
        <span className="input-carrets">{">>>"}</span>
        <input autoFocus={true} className="input" type="text" value={title} onChange={handleChangeTitle} placeholder="Title" />
        <span className="input-carrets">{"<<<"}</span>
      </div>
      <div className="input-wrapper">
        <span className="input-carrets">{">>>"}</span>
        <input className="input" value={description} onChange={handleChangeDescription} placeholder="Description" />
        <span className="input-carrets">{"<<<"}</span>
      </div>
      <div className="rmodal-actions">
        <a className="button button--fuschia" onClick={props.closeModal}>
          Cancel
        </a>
        <button type="submit" className="button button--blue">
          Save
        </button>
      </div>
    </form>
  );
}
