import OrganisationAdmin from "./MainContent";
import "./index.scss";

export default function OrganisationPage() {
  return (
    <div className="organisation-page">
      <OrganisationAdmin />
    </div>
  );
}
