import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

export const ERROR = "error";
export const WARNING = "warning";
export const SUCCESS = "success";
export const DISCONNECT = "disconnect";

export type NotificationType = typeof ERROR | typeof WARNING | typeof SUCCESS | typeof DISCONNECT;

export interface Notification {
  type: NotificationType;
  message: string;
  id: string;
}

export interface NotificationReducerState {
  notifications: Notification[];
}

const initialState: NotificationReducerState = {
  notifications: []
};

export const addAutoRemovableNotification = createAsyncThunk("notifications/addAutoRemovable", async (notification: Omit<Notification, "id">, { dispatch }) => {
  const id = Date.now() + "" + Math.random();
  setTimeout(() => {
    dispatch({
      type: "notifications/deleteNotification",
      payload: id
    });
  }, 3500);
  return { ...notification, id } as Notification;
});

const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    deleteNotification: (state, action: PayloadAction<string>) => {
      state.notifications = state.notifications.filter((n) => n.id !== action.payload);
    }
  },
  extraReducers: (builder) => {
    builder.addCase(addAutoRemovableNotification.fulfilled, (state, action) => {
      state.notifications.push(action.payload);
    });
  }
});

export const { deleteNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
