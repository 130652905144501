import "./index.scss";

interface ToggleProps {
  checked: boolean;
  handleChange: () => void;
}

export default function Toggle(props: ToggleProps) {
  return (
    <label className="toggle">
      <input className="input" type="checkbox" onChange={props.handleChange} checked={props.checked} />
      <span className="toggle__slider">
        <span className="toggle__slider-info">off</span>
        <span className="toggle__slider-info">on</span>
      </span>
    </label>
  );
}
