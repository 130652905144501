import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addAutoRemovableNotification } from "../notificationsReducer";

export interface Organisation {
  title: string;
  shortTitle: string;
  _id: string;
}

export interface applicationAdminDashboardReducerState {
  organisations: Organisation[];
  count: number;
}

const initialState: applicationAdminDashboardReducerState = {
  organisations: [],
  count: 0
};

export const getAllOrganisations = createAsyncThunk("applicationAdminDashboard/getAllOrganisations", async (page: number) => {
  const response = await axios.get(`/api/organisations?page=${page}`);
  return response.data as { organisations: Organisation[]; totalCount: number };
});

export const getOrganisation = createAsyncThunk("applicationAdminDashboard/getOrganisation", async (id: string | undefined) => {
  const response = await axios.get(`/api/organisations/${id}`);
  return response.data as Organisation;
});

export const createOrganisation = createAsyncThunk(
  "applicationAdminDashboard/createOrganisation",
  async (organisation: { title: string; shortTitle: string }, { dispatch }) => {
    const { title, shortTitle } = organisation;
    try {
      const response = await axios.post(`/api/organisations`, { title, shortTitle });
      dispatch(
        addAutoRemovableNotification({
          message: "Created an organisation",
          type: "success"
        })
      );
      return response.data as Organisation;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const cause = e.response?.data || e.message;
        dispatch(
          addAutoRemovableNotification({
            message: `Not able to create an organisation: ${cause}`,
            type: "error"
          })
        );
      }

      throw new Error();
    }
  }
);

export const updateOrganisation = createAsyncThunk(
  "applicationAdminDashboard/updateOrganisation",
  async (organisation: { id: string; title: string; shortTitle: string }, { dispatch }) => {
    const { title, id, shortTitle } = organisation;
    try {
      const response = await axios.patch(`/api/organisations/${id}`, { title, shortTitle });
      dispatch(
        addAutoRemovableNotification({
          message: "Organisation title is updated",
          type: "success"
        })
      );
      return response.data as Organisation;
    } catch (e) {
      dispatch(
        addAutoRemovableNotification({
          message: "Not able to update an organisation",
          type: "error"
        })
      );
      throw new Error();
    }
  }
);

export const removeOrganisation = createAsyncThunk("applicationAdminDashboard/removeOrganisation", async (id: string, { dispatch }) => {
  try {
    await axios.delete(`/api/organisations/${id}`);
    dispatch(
      addAutoRemovableNotification({
        message: "Organisation is removed",
        type: "success"
      })
    );
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const cause = e.response?.data || e.message;
      dispatch(
        addAutoRemovableNotification({
          message: `Not able to remove an organisation: ${cause}`,
          type: "error"
        })
      );
    }
    throw new Error();
  }
  return id;
});

export const applicationadminDashboardSlice = createSlice({
  name: "applicationAdminDashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllOrganisations.fulfilled, (state, action) => {
      state.organisations = action.payload.organisations;
      state.count = action.payload.totalCount;
    });
    builder.addCase(getOrganisation.fulfilled, (state, action) => {
      state.organisations = state.organisations.filter(({ _id }) => _id !== action.payload._id);
      state.organisations.push(action.payload);
    });
    builder.addCase(createOrganisation.fulfilled, (state, action) => {
      state.organisations.unshift(action.payload);
      state.count = state.count + 1;
    });
    builder.addCase(updateOrganisation.fulfilled, (state, action) => {
      state.organisations = state.organisations.filter(({ _id }) => _id !== action.payload._id).concat(action.payload);
    });
    builder.addCase(removeOrganisation.fulfilled, (state, action) => {
      state.organisations = state.organisations.filter(({ _id }) => _id !== action.payload);
      state.count = state.count - 1;
    });
  }
});

export default applicationadminDashboardSlice.reducer;
