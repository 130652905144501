import React, { useState } from "react";
import { useAppSelector } from "../../../redux/hooks";
import { userSelector } from "../../../redux/userReducer/selectors";
import { sessionRoomSelector } from "../../../redux/sessionRoomReducer/selectors";
import { useAppDispatch } from "../../../redux/hooks";
import { changeUserName } from "../../../services/socketsHandler";
import { addAutoRemovableNotification } from "../../../redux/notificationsReducer";
import Toggle from "../Toggle";
import "./index.scss";

interface SettingsProps {
  onClose: () => void;
}

export default function Settings(props: SettingsProps) {
  const user = useAppSelector(userSelector);
  const session = useAppSelector(sessionRoomSelector);
  const [userName, setUserName] = useState(user.userName || "");
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "dark");
  const dispatch = useAppDispatch();

  const changeTheme = () => {
    const newTheme = theme === "dark" ? "light" : "dark";
    localStorage.setItem("theme", newTheme);
    setTheme(newTheme);
    document.documentElement.className = `${newTheme}-theme`;
  };

  const onUserNameChange = (event: React.FormEvent<HTMLInputElement>) => {
    setUserName(event.currentTarget.value);
  };

  const onCancel = () => {
    props.onClose();
  };

  const submitUserName = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isEstimationInProgress() && !user.isAdmin) {
      changeUserName(userName);
    }

    if (isEstimationInProgress()) {
      dispatch(addAutoRemovableNotification({ message: "Cannot change name when estimation is active", type: "warning" }));
    }

    if (user.isAdmin) {
      dispatch(addAutoRemovableNotification({ message: "Logged users cannot change name", type: "warning" }));
    }

    props.onClose();
  };

  const isEstimationInProgress = (): boolean => {
    if (!session) {
      return false;
    }
    return session.stories.filter((story) => story.status === "progress").length > 0;
  };

  return (
    <form className="rmodal" onSubmit={submitUserName}>
      <p className="rmodal__title">Settings</p>
      <div className="input-wrapper">
        <span className="input-carrets">{">>>"}</span>
        <input className="input" autoFocus={true} value={userName} type="text" onInput={onUserNameChange} placeholder="Name" />
        <span className="input-carrets">{"<<<"}</span>
      </div>
      <div className="rmodal-actions">
        <button type="submit" className={`button button--blue`}>
          Update name
        </button>
      </div>
      <div className="theme-toggle">
        <span className="theme-toggle__label">Dark Theme</span>
        <Toggle checked={theme === "light"} handleChange={changeTheme} />
      </div>
      <div className="rmodal-actions">
        <a className="button button--fuschia" onClick={onCancel}>
          Cancel
        </a>
      </div>
    </form>
  );
}
