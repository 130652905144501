import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./styles.scss";

interface JoinRoomProps {
  onClose: () => void;
}

export default function JoinRoom(props: JoinRoomProps) {
  const [roomId, setRoomId] = useState("");
  const navigate = useNavigate();

  const handleInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    setRoomId(event.currentTarget.value);
  };

  const handleJoin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!roomId) {
      return;
    }
    navigate(`/planningsession/${roomId}`);
  };

  return (
    <form className="rmodal" onSubmit={handleJoin}>
      <p className="rmodal__title">Enter Room Id</p>
      <div className="input-wrapper">
        <span className="input-carrets">{">>>"}</span>
        <input className="input" autoFocus={true} value={roomId} onInput={handleInputChange} />
        <span className="input-carrets">{"<<<"}</span>
      </div>
      <div className="rmodal-actions">
        <a className="button button--fuschia" onClick={props.onClose}>
          Cancel
        </a>
        <button type="submit" className="button button--blue">
          Join
        </button>
      </div>
    </form>
  );
}
