import { restoreBackup, Backup } from "../../../redux/backupsReducer/index";
import { useAppDispatch } from "../../../redux";
import React from "react";

interface RestoreBackupProps {
  closeModal: () => void;
  backup: Backup;
}

export default function RestoreBackup(props: RestoreBackupProps) {
  const dispatch = useAppDispatch();

  const restoreHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(restoreBackup(props.backup.time));
    props.closeModal();
  };

  return (
    <form className="rmodal" onSubmit={restoreHandler}>
      <p className="rmodal__title">Restore Backup</p>
      <div className="input-wrapper">
        <span className="input-carrets">{">>>"}</span>
        <input className="input" disabled value={props.backup.time} />
        <span className="input-carrets">{"<<<"}</span>
      </div>
      <div className="rmodal-actions">
        <a className="button button--fuschia" onClick={props.closeModal}>
          No
        </a>
        <button type="submit" className="button button--blue" autoFocus={true}>
          Yes
        </button>
      </div>
    </form>
  );
}
