import "./index.scss"
import React, { useState } from "react";
import { createBackup } from "../../../redux/backupsReducer";
import { useAppDispatch } from "../../../redux";
import RModal from "../Modal";

export default function NewBackup() {
  const dispatch = useAppDispatch();
  const [isModalOpen, setModalOpen] = useState(false);

  const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(createBackup());
    handleClose();
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <div className="new-backup">
      <a className="button button--purple" onClick={() => setModalOpen(true)}>
        Create
      </a>
      <RModal isOpen={isModalOpen} onRequestClose={handleClose}>
        <form className="rmodal" onSubmit={handleSave}>
          <p className="rmodal__backup-title">Create Backup</p>
          <div className="rmodal-actions">
            <a className="button button--fuschia" onClick={handleClose}>
              Discard
            </a>
            <button className="button button--blue" type="submit">
              Create
            </button>
          </div>
        </form>
      </RModal>
    </div>
  );
}
