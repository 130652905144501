import { removeBackup, Backup } from "../../../redux/backupsReducer/index";
import { useAppDispatch } from "../../../redux";
import React from "react";

interface RemoveBackupProps {
  closeModal: () => void;
  backup: Backup;
}

export default function RemoveBackup(props: RemoveBackupProps) {
  const dispatch = useAppDispatch();

  const removeHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(removeBackup(props.backup.time));
    props.closeModal();
  };

  return (
    <form className="rmodal" onSubmit={removeHandler}>
      <p className="rmodal__title">Delete Backup</p>
      <div className="input-wrapper">
        <span className="input-carrets">{">>>"}</span>
        <input className="input" disabled value={props.backup.time} />
        <span className="input-carrets">{"<<<"}</span>
      </div>
      <div className="rmodal-actions">
        <a className="button button--fuschia" onClick={props.closeModal}>
          No
        </a>
        <button type="submit" className="button button--blue" autoFocus={true}>
          Yes
        </button>
      </div>
    </form>
  );
}
