import React, { useState } from "react";
import { useAppDispatch } from "../../../redux";
import { updateOrganisation } from "../../../redux/applicationAdminDashboardReducer";
import { Organisation } from "../../../redux/applicationAdminDashboardReducer";

interface EditOrganisationProps {
  organisation: Organisation;
  discard: () => void;
}

export default function EditOrganisation(props: EditOrganisationProps) {
  const dispatch = useAppDispatch();
  const [newTitle, setNewTitle] = useState(props.organisation.title);
  const [newShortTitle, setNewShortTitle] = useState(props.organisation.shortTitle);

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const title = e.target.value;
    setNewTitle(title);
  };

  const handleShortTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const shortTitle = e.target.value;
    setNewShortTitle(shortTitle);
  };

  const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(updateOrganisation({ id: props.organisation._id, title: newTitle, shortTitle: newShortTitle }));
    props.discard();
  };

  return (
    <form className="rmodal" onSubmit={handleSave}>
      <p className="rmodal__title">Edit Organisation</p>
      <div className="input-wrapper">
        <span className="input-carrets">{">>>"}</span>
        <input className="input" autoFocus={true} onChange={handleTitleChange} value={newTitle} placeholder="Title" />
        <span className="input-carrets">{"<<<"}</span>
      </div>
      <div className="input-wrapper">
        <span className="input-carrets">{">>>"}</span>
        <input className="input" name="shortTitle" id="shortTitle" onChange={handleShortTitleChange} value={newShortTitle} placeholder="Short title" />
        <span className="input-carrets">{"<<<"}</span>
      </div>
      <div className="rmodal-actions">
        <a className="button button--fuschia" onClick={props.discard}>
          Cancel
        </a>
        <button type="submit" className="button button--blue">
          Save
        </button>
      </div>
    </form>
  );
}
