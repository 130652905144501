import { Story } from "../../../redux/sessionRoomReducer";
import { deleteStory } from "../../../services/socketsHandler";

interface RemoveStoryProps {
  story: Story;
  closeModal: () => void;
}

export default function RemoveStory(props: RemoveStoryProps) {
  const deleteStoryHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    deleteStory(props.story.id);
    props.closeModal();
  };

  return (
    <form className="rmodal" onSubmit={deleteStoryHandler}>
      <p className="rmodal__title">Delete Story</p>
      <div className="input-wrapper">
        <span className="input-carrets">{">>>"}</span>
        <input className="input" disabled value={props.story.title} />
        <span className="input-carrets">{"<<<"}</span>
      </div>
      <div className="rmodal-actions">
        <a className="button button--fuschia" onClick={props.closeModal}>
          No
        </a>
        <button type="submit" className="button button--blue" autoFocus={true}>
          Yes
        </button>
      </div>
    </form>
  );
}
