import "./index.scss";
import OrganisationsList from "../../content/OrganisationsList";
import NewOrganisation from "../../content/NewOrganisation";

export default function OrganisationsPage() {
  return (
    <div className="organisations-dashboard">
      <p className="organisations-dashboard__title">Organisations</p>
      <OrganisationsList />
      <NewOrganisation />
    </div>
  );
}
