import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addAutoRemovableNotification } from "../notificationsReducer";

export interface storyUrlMapping {
  _id: string;
  storyNaming: string;
  prefixUrl: string;
  organisationId: string;
}

export interface storyUrlMappingReducerState {
  storyUrlMappings: storyUrlMapping[];
}

const initialState: storyUrlMappingReducerState = {
  storyUrlMappings: []
};

export const getStoryUrlMappings = createAsyncThunk("storyUrlMappings/getAStoryUrlMappings", async (organisationId: string | undefined) => {
  const response = await axios.get(`/api/storyUrlMapping?organisationId=${organisationId}`);
  return response.data as storyUrlMapping[];
});

export const removeStoryUrlMapping = createAsyncThunk("storyUrlMapping/removeStoryUrlMapping", async (id: string, { dispatch }) => {
  try {
    await axios.delete(`/api/storyUrlMapping/${id}`);
    dispatch(
      addAutoRemovableNotification({
        message: "Story url mapping is removed",
        type: "success"
      })
    );
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const cause = e.response?.data || e.message;
      dispatch(
        addAutoRemovableNotification({
          message: `Not able to remove story url mappins: ${cause}`,
          type: "error"
        })
      );
    }
    throw new Error();
  }
  return id;
});

export const createStoryUrlMapping = createAsyncThunk("storyUrlMapping/createStoryUrlMapping", async (storyUrlMappings: { storyNaming: string; organisationId: string | undefined, prefixUrl: string}, { dispatch }) => {
    const { storyNaming, organisationId, prefixUrl } = storyUrlMappings;
    try {
      const response = await axios.post(`/api/storyUrlMapping`, { storyNaming, organisationId, prefixUrl });
      dispatch(
        addAutoRemovableNotification({
          message: "Created a story url mapping",
          type: "success"
        })
      );
      return response.data as storyUrlMapping;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const cause = e.response?.data || e.message;
        dispatch(
          addAutoRemovableNotification({
            message: `Not able to create a story url mapping: ${cause}`,
            type: "error"
          })
        );
      }
      throw new Error();
    }
  }
);

export const updateStoryUrlMapping = createAsyncThunk("storyUrlMapping/updateStoryUrlMapping", async (storyUrlMappings: { storyNaming: string; id: string; prefixUrl: string; organisationId: string }, { dispatch }) => {
    const { storyNaming, id, prefixUrl, organisationId } = storyUrlMappings;
    const response = await axios.patch(`/api/storyUrlMapping/${id}`, { storyNaming, prefixUrl, organisationId });
    try {
      dispatch(
        addAutoRemovableNotification({
          message: "Story url mapping is updated",
          type: "success"
        })
      );
      return response.data as storyUrlMapping;
    } catch (e) {
      dispatch(
        addAutoRemovableNotification({
          message: "Not able to update a story url mapping",
          type: "error"
        })
      );
      throw new Error();
    }
  }
);

export const storyUrlMappingSlice = createSlice({
  name: "storyUrlMapping",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStoryUrlMappings.fulfilled, (state, action) => {
      state.storyUrlMappings = action.payload;
    });
    builder.addCase(createStoryUrlMapping.fulfilled, (state, action) => {
      state.storyUrlMappings.unshift(action.payload);
    });
    builder.addCase(updateStoryUrlMapping.fulfilled, (state, action) => {
      state.storyUrlMappings = state.storyUrlMappings.filter(({ _id }) => _id !== action.payload._id).concat(action.payload);
    });
    builder.addCase(removeStoryUrlMapping.fulfilled, (state, action) => {
      state.storyUrlMappings = state.storyUrlMappings.filter(({ _id }) => _id !== action.payload);
    });
  }
});

export default storyUrlMappingSlice.reducer;
