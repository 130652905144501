import { store } from "../redux";
import { io, Socket } from "socket.io-client";
import { SessionRoom, updateSessionRoom } from "../redux/sessionRoomReducer";
import history from "../utils/routerUtils";
import { addAutoRemovableNotification, DISCONNECT, Notification } from "../redux/notificationsReducer";
import { actions } from "../redux/globalErrorStateReducer";

let socket: Socket;

const getToken = () => {
  const tokenParamTuple =
    window.location.search &&
    window.location.search
      .slice(1, window.location.search.length)
      .split("&")
      .map((searchPair) => searchPair.split("="))
      .find((searchTuple) => searchTuple[0] === "token");
  return tokenParamTuple ? tokenParamTuple[1] : "";
};

export const openConnection = (userName: string, roomId: string, userId: string) => {
  store.dispatch(actions.updateStatus({ connectionStatus: "connected" }));

  socket = io("/");
  socket.emit("enterroom", { userName, roomId, userId, token: getToken() });

  socket.on("roomupdate", (sessionRoom: SessionRoom) => {
    store.dispatch(updateSessionRoom(sessionRoom));
  });

  socket.on("roomnonexist", () => {
    history.push("/");
  });

  socket.on("notification", (notification: Omit<Notification, "id">) => {
    if (notification.type === DISCONNECT) {
      store.dispatch(actions.updateError({ error: notification.message }));
    } else {
      store.dispatch(addAutoRemovableNotification(notification));
    }
  });

  socket.on("disconnect", () => {
    store.dispatch(actions.updateStatus({ connectionStatus: "disconnected" }));
  });

  socket.on("connect_error", () => {
    store.dispatch(addAutoRemovableNotification({ message: "Connection Error", type: "error" }));
  });
  socket.on("connect_failed", () => {
    store.dispatch(addAutoRemovableNotification({ message: "Connection Failed", type: "error" }));
  });
};

export const closeConnection = () => {
  if (socket && socket.active) {
    socket.close();
  }
};

export const addStory = (title: string, description: string) => {
  if (!socket.connected) {
    store.dispatch(addAutoRemovableNotification({ message: "Not able to add a story", type: "error" }));
    return;
  }
  socket.emit("addstory", { title, description });
};

export const editStory = (id: string, title: string, description: string) => {
  if (!socket.connected) {
    store.dispatch(addAutoRemovableNotification({ message: "Not able to edit a story", type: "error" }));
    return;
  }
  socket.emit("editstory", { title, description, id });
};

export const sortStory = (id: string) => {
  if (!socket.connected) {
    store.dispatch(addAutoRemovableNotification({ message: "Not able to sort stories", type: "error" }));
    return;
  }
  socket.emit("sortstory", { id });
};

export const setActiveStory = (storyId: string) => {
  if (!socket.connected) {
    store.dispatch(addAutoRemovableNotification({ message: "Not able to set active a story", type: "error" }));
    return;
  }
  socket.emit("setactivestory", { storyId });
};

export const deleteStory = (id: string) => {
  if (!socket.connected) {
    store.dispatch(addAutoRemovableNotification({ message: "Not able to delete a story", type: "error" }));
    return;
  }
  socket.emit("deletestory", { id });
};

export const startVoting = () => {
  if (!socket.connected) {
    store.dispatch(addAutoRemovableNotification({ message: "Not able to start a voting", type: "error" }));
    return;
  }
  socket.emit("startvoting");
};

export const endVoting = () => {
  if (!socket.connected) {
    store.dispatch(addAutoRemovableNotification({ message: "Not able to end a voting", type: "error" }));
    return;
  }
  socket.emit("endvoting");
};

export const changeUserName = (userName: string) => {
  if (!socket.connected) {
    store.dispatch(addAutoRemovableNotification({ message: "Not able to change a name", type: "error" }));
    return;
  }
  socket.emit("changename", { userName });
  store.dispatch({ type: "user/setUserName", payload: userName });
};

export const vote = (mark: string) => {
  if (!socket.connected) {
    store.dispatch(addAutoRemovableNotification({ message: "Not able to vote", type: "error" }));
    return;
  }
  socket.emit("vote", { mark });
};

export const setFinalVote = (mark: string) => {
  if (!socket.connected) {
    store.dispatch(addAutoRemovableNotification({ message: "Not able to set a final vote", type: "error" }));
    return;
  }
  socket.emit("finalvote", { mark });
};
