import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import dashboardReducer from "./dashboardReducer";
import userReducer from "./userReducer";
import sessionRoomReducer from "./sessionRoomReducer";
import notificationsReducer from "./notificationsReducer";
import { useDispatch } from "react-redux";
import admindashboardReducer from "./admindashboardReducer/admindashboardRedux";
import applicationAdminDashboardReducer from "./applicationAdminDashboardReducer";
import organisationPageReducer from "./organisationPageReducer";
import storyUrlMappingReducer from "./storyUrlMappingReducer";
import backupsReducer from "./backupsReducer";
import globalErrorStateReducer from "./globalErrorStateReducer";

export const store = configureStore({
  reducer: {
    dashboardReducer,
    userReducer,
    sessionRoomReducer,
    admindashboardReducer,
    applicationAdminDashboardReducer,
    notificationsReducer,
    organisationPageReducer,
    storyUrlMappingReducer,
    backupsReducer,
    globalErrorStateReducer
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
