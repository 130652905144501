import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ConnectionStatusReducerState {
  connectionStatus: string;
  error: string | null;
}

export interface ConnectionStatusPayload {
  connectionStatus: string;
}

export interface ConnectionErrorPayload {
  error: string | null;
}

const initialState: ConnectionStatusReducerState = {
  connectionStatus: "connected",
  error: null
};

const socketConnectionSlice = createSlice({
  name: "socketConnection",
  initialState,
  reducers: {
    updateStatus(state, action: PayloadAction<ConnectionStatusPayload>) {
      state.connectionStatus = action.payload.connectionStatus;
    },
    updateError(state, action: PayloadAction<ConnectionErrorPayload>) {
      state.error = action.payload.error;
    }
  }
});

export const { actions, reducer, name: sliceKey } = socketConnectionSlice;

export default socketConnectionSlice.reducer;
