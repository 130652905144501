import { useAppSelector } from "../../../redux/hooks";
import { Story } from "../../../redux/sessionRoomReducer";
import { sessionRoomSelector } from "../../../redux/sessionRoomReducer/selectors";
import { selectedStorySelector } from "../../../redux/userReducer/selectors";
import { getSelectedStory } from "../../../utils/sessionUtils";
import "./styles.scss";

export default function UsersComponent() {
  const session = useAppSelector(sessionRoomSelector);
  const selectedStoryId = useAppSelector(selectedStorySelector);
  const voteUsersMap: Map<string, string[]> = new Map();
  const colorClassMap = ["green", "yellow", "blue", "purple", "orange", "fuschia"];

  const resetVoteUsersMap = () => {
    ["1", "2", "3", "5", "8", "13", "100500", "skip", "not voted"].map((estimation) => voteUsersMap.set(estimation, []));
  };

  const getVote = (vote?: string) => {
    if (!vote) {
      return null;
    }
    return <span className="users-component__user-vote">[Voted]</span>;
  };

  const getUser = (userName: string, storyStatus: string, vote?: string) => {
    let className = "users-component__user";
    className += storyStatus === "progress" ? " users-component__user--pending" : "";
    className += !!vote ? " users-component__user--voted" : "";
    return (
      <div key={userName} className={className}>
        <span className="users-component__user-name">{userName}</span>
        {getVote(vote)}
      </div>
    );
  };

  const getInProgressStory = (story?: Story) => {
    if (!story) {
      return session?.users.map((user) => {
        return getUser(user.userName, "", "");
      });
    }

    const votedUsers: { userName: string; estimation?: string }[] = [];
    const notVotedUsers: { userName: string; estimation?: string }[] = [];
    session?.users.forEach((user) => {
      const userVote = story?.currentVoting.find((currentVote) => user.userId === currentVote.userId);
      if (userVote?.estimation) {
        votedUsers.push({ userName: user.userName, estimation: userVote.estimation });
        return;
      }
      notVotedUsers.push({ userName: user.userName, estimation: undefined });
    });
    return [...notVotedUsers, ...votedUsers].map((vote) => {
      return getUser(vote.userName, story.status, vote?.estimation);
    });
  };

  const getVoteElementUser = (user: string) => {
    return <div className="users-component__vote-element-user">{user}</div>;
  };

  const getVoteElement = (vote: string, users: string[], colorStyle: string) => {
    if (!users.length) {
      return null;
    }
    return (
      <div key={vote} className={`users-component__vote-element ${colorStyle}-text`}>
        <div className="users-component__vote-element-estimation">{`<${vote}>`}</div>
        <div className="users-component__vote-element-users">{users.map(getVoteElementUser)}</div>
      </div>
    );
  };

  const getVotedStoryUsers = (story: Story) => {
    resetVoteUsersMap();
    const votesElements: (JSX.Element | null)[] = [];
    let colorIndex = 0;
    story.votes.forEach((vote) => voteUsersMap.get(vote.estimation)?.push(vote.userName));
    voteUsersMap.forEach((users, vote) => {
      const colorStyle = colorClassMap[colorIndex++];
      votesElements.push(getVoteElement(vote, users, colorStyle));
      if (colorIndex >= colorClassMap.length) {
        colorIndex = 0;
      }
    });
    return votesElements;
  };

  const getUsers = () => {
    const story = getSelectedStory(session, selectedStoryId);
    if (story?.status === "voted") {
      return getVotedStoryUsers(story);
    } else {
      return getInProgressStory(story);
    }
  };

  return (
    <div className="users-component">
      <div className="users-component__head">Users</div>
      {getUsers()}
    </div>
  );
}
