import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { socketDisconnectedSelector } from "../../../redux/globalErrorStateReducer/selectors";
import { useAppSelector } from "../../../redux/hooks";
import RModal from "../Modal";
import "./styles.scss";

export default function SocketDisconnectedModal() {
  const { connectionStatus, error } = useAppSelector(socketDisconnectedSelector);
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (connectionStatus === "disconnected") {
      setModalOpen(true);
    }
  }, []);

  const refreshPage = () => {
    window.location.reload();
  };

  const onClose = () => {
    if (connectionStatus === "disconnected") {
      return;
    }
    window.location.reload();
    setModalOpen(false);
  };

  const getModalContent = () => {
    if (error) {
      return (
        <div className="rmodal">
          <p className="rmodal__disconected-title">{error}</p>
          <div className="rmodal-actions-disconnect ">
            <Link to={`/`} className="button button--blue">
              Home Page
            </Link>
          </div>
        </div>
      );
    } else {
      return (
        <div className="rmodal">
          <p className="rmodal__disconected-title">{"Lost Connection"}</p>
          <div className="rmodal-actions-disconnect ">
            <a className="button button--fuschia" onClick={refreshPage}>
              Refresh Page
            </a>
            <Link to={`/`} className="button button--blue">
              Home Page
            </Link>
          </div>
        </div>
      );
    }
  };

  return (
    <RModal isOpen={isModalOpen} onRequestClose={onClose}>
      {getModalContent()}
    </RModal>
  );
}
