import "./styles.scss";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getAllBackups } from "../../../redux/backupsReducer";
import { backupsFromStore } from "../../../redux/backupsReducer/selectors";
import RemoveBackup from "../../content/RemoveBackup";
import RestoreBackup from "../../content/RestoreBackup";
import RModal from "../Modal";

export default function BackupsList() {
  const dispatch = useAppDispatch();
  const backups = useAppSelector(backupsFromStore);
  const [activeBackupName, setActiveBackupName] = useState("");
  const [removeBackup, setRemoveBackup] = useState("");
  const [restoreBackup, setRestoreBackup] = useState("");

  useEffect(() => {
    dispatch(getAllBackups());
  }, []);

  const handleSelect = (time: string) => {
    if (activeBackupName && activeBackupName === time) {
      setActiveBackupName("");
    } else {
      setActiveBackupName(time);
    }
  };

  const backupsList = () => {
    if (!backups.length) {
      return <span>No backups for now</span>;
    }
    return backups.map(({ time }) => {
      return (
        <div className="backups" key={time} onClick={() => handleSelect(time)}>
          <div className="backups__meta">
            <p className="backups__name" key={time}>
              {time}
            </p>
          </div>
          <div className={`backups__actions ${time === activeBackupName ? "backups__actions--active" : ""}`}>
            <a className="button button--orange" onClick={() => setRemoveBackup(time)}>
              Remove
            </a>
            <a className="button button--green" onClick={() => setRestoreBackup(time)}>
              Restore
            </a>
          </div>
        </div>
      );
    });
  };

  const renderRemoveBackup = () => {
    if (!removeBackup) {
      return;
    }

    const backup = backups.find(({ time }) => time === removeBackup);

    if (!backup) {
      return;
    }

    return (
      <RModal isOpen={true} onRequestClose={() => setRemoveBackup("")}>
        <RemoveBackup backup={backup} closeModal={() => setRemoveBackup("")} />
      </RModal>
    );
  };

  const renderRestoreBackup = () => {
    if (!restoreBackup) {
      return;
    }

    const backup = backups.find(({ time }) => time === restoreBackup);

    if (!backup) {
      return;
    }

    return (
      <RModal isOpen={true} onRequestClose={() => setRestoreBackup("")}>
        <RestoreBackup backup={backup} closeModal={() => setRestoreBackup("")} />
      </RModal>
    );
  };

  return (
    <>
      <div className="backups__content">{backupsList()}</div>
      {renderRemoveBackup()}
      {renderRestoreBackup()}
    </>
  );
}
