import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { Story } from "../../../redux/sessionRoomReducer";
import { sessionRoomSelector } from "../../../redux/sessionRoomReducer/selectors";
import { setSelectedStory } from "../../../redux/userReducer";
import { selectedStorySelector, storyViewModeSelector, userIdSelector } from "../../../redux/userReducer/selectors";
import RemoveStory from "../RemoveStory";
import EditStory from "../EditStory";
import RModal from "../Modal";
import { addAutoRemovableNotification } from "../../../redux/notificationsReducer";
import "./styles.scss";
import { setActiveStory } from "../../../services/socketsHandler";
import { replaceTextToLinks } from "../../../utils/sessionUtils";

interface StorySectionProps {
  story: Story;
  handleClickWhenAutoModeEnabled?: () => void;
}

export default function StorySection(props: StorySectionProps) {
  const session = useAppSelector(sessionRoomSelector);
  const selectedStoryId = useAppSelector(selectedStorySelector);
  const storyViewMode = useAppSelector(storyViewModeSelector);
  const userId = useAppSelector(userIdSelector);
  const dispatch = useAppDispatch();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);

  const storySectionClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if ((event.target as Element).className?.indexOf("story-section") === -1) {
      return;
    }
    if (session?.adminId === userId && session.activeStoryId !== props.story.id) {
      const inProgressStory = session.stories.find((story) => story.status === "progress");
      if (inProgressStory && inProgressStory.id !== props.story.id) {
        return;
      }
      setActiveStory(props.story.id);
    }

    if (storyViewMode !== "manual" && session?.adminId !== userId) {
      props.handleClickWhenAutoModeEnabled && props.handleClickWhenAutoModeEnabled();
      dispatch(addAutoRemovableNotification({ type: "warning", message: "Cannot change story in Auto navigation mode" }));
      return;
    }
    dispatch(setSelectedStory(props.story.id === session?.activeStoryId ? "" : props.story.id));
  };

  const getClassName = () => {
    const isStoryActive = props.story.id === session?.activeStoryId;
    const isStorySelected = selectedStoryId === props.story.id;
    const isStoryVoted = props.story.status === "voted";
    const isStoryProgress = props.story.status === "progress";
    let className = "story-section";
    if (isStoryActive) {
      className += " story-section--active";
    }
    if (isStorySelected) {
      className += " story-section--selected";
    }
    if (isStoryProgress) {
      className += " story-section--progress";
    }
    if (isStoryVoted) {
      className += " story-section--voted";
    }
    if (!userId || userId !== session?.adminId) {
      className += " non-admin";
    }
    return className;
  };

  const getActionButtons = () => {
    if (!userId || userId !== session?.adminId) {
      return null;
    }
    return (
      <>
        <a className="button" onClick={() => setEditModalOpen(true)}>
          Edit
        </a>
        <a className="button" onClick={() => setDeleteModalOpen(true)}>
          Remove
        </a>
      </>
    );
  };

  const getDiscussungStatus = () => {
    return props.story.id === session?.activeStoryId ? <span className="story-section__active-status">{`<Discussing now>`}</span> : null;
  };
  const getVotedStatus = () => {
    if (props.story.status !== "voted") {
      return null;
    }
    return props.story.finalVote ? (
      <span className="story-section__voted-status">{`<Final Vote: ${props.story.finalVote}>`}</span>
    ) : (
      <span className="story-section__voted-status">{`<Voted>`}</span>
    );
  };
  const getProgressStatus = () => {
    return props.story.status === "progress" ? <span className="story-section__progress-status">{`<Voting>`}</span> : null;
  };

  const getAdditionalSection = () => {
    return (
      <div className="story-section__status">
        {getDiscussungStatus()}
        {getVotedStatus()}
        {getProgressStatus()}
      </div>
    );
  };

  const renderEditModal = () => {
    if (!userId || userId !== session?.adminId) {
      return null;
    }
    return (
      <RModal isOpen={isEditModalOpen} onRequestClose={() => setEditModalOpen(false)}>
        <EditStory story={props.story} closeModal={() => setEditModalOpen(false)} />
      </RModal>
    );
  };

  const renderDeleteModal = () => {
    if (!userId || userId !== session?.adminId) {
      return null;
    }
    return (
      <RModal isOpen={isDeleteModalOpen} onRequestClose={() => setDeleteModalOpen(false)}>
        <RemoveStory story={props.story} closeModal={() => setDeleteModalOpen(false)} />
      </RModal>
    );
  };

  return (
    <>
      <div onClick={storySectionClick} className={getClassName()}>
        <div className="story-info">
          <div className="story-section__story-name">Name: {replaceTextToLinks(props.story.processedTitle)}</div>
          {getAdditionalSection()}
        </div>
        <div className="story-actions">{getActionButtons()}</div>
      </div>
      {renderDeleteModal()}
      {renderEditModal()}
    </>
  );
}
