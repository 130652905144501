import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { setSelectedStory } from "../userReducer";

export interface Vote {
  userName: string;
  estimation: string;
  userId: string;
}

export interface User {
  userName: string;
  userId: string;
}

export interface Story {
  title: string;
  description: string;
  status: string;
  id: string;
  votes: Vote[];
  finalVote: string;
  currentVoting: Vote[];
  processedTitle: string;
  processedDescription: string;
}

export interface SessionRoom {
  title: string;
  stories: Story[];
  id: string;
  users: User[];
  adminId: string;
  activeStoryId: string;
}

export interface SessionRoomState {
  sessionRoom?: SessionRoom;
}

const initialState: SessionRoomState = {};

export const updateSessionRoom = createAsyncThunk("sessionRoom/updateSessionRoom", async (sessionRoom: SessionRoom, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  const selectedStory = state.userReducer.selectedStory;
  if (!sessionRoom.stories.find((story) => story.id === selectedStory)) {
    thunkAPI.dispatch(setSelectedStory(""));
  }
  return sessionRoom;
});

export const sessionRoomSlice = createSlice({
  name: "sessionRoom",
  initialState,
  reducers: {
    setSessionRoom: (state, action: PayloadAction<{ sessionRoom: SessionRoom }>) => {
      state.sessionRoom = action.payload.sessionRoom;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(updateSessionRoom.fulfilled, (state, action) => {
      state.sessionRoom = action.payload;
    });
  }
});

export const { setSessionRoom } = sessionRoomSlice.actions;

export default sessionRoomSlice.reducer;
