import "./index.scss";
import { useSearchParams } from "react-router-dom";
import history from "../../../utils/routerUtils";

interface PaginationProps {
  totalPages: number;
  link: string;
}

export default function Pagination(props: PaginationProps): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPageProp = parseInt(searchParams.get("page") || "0");

  const createPagination = (currentPage: number, totalPages: number) => {
    const pageNumbers = [];

    for (let i = 0; i < totalPages; i++) {
      if (i === 0 || i === totalPages - 1 || Math.abs(i - currentPage) < 2) {
        pageNumbers.push(i + "");
      }

      if ((i === 1 || i === totalPages - 2) && currentPage < totalPages && Math.abs(i - currentPage) >= 2) {
        pageNumbers.push("...");
      }
    }

    return (
      <ul className="pagination__list">
        {pageNumbers.map((pageNumber) => {
          if (pageNumber === "...") {
            return (
              <li key={pageNumber} className="pagination__numbers">
                <a className="pagination__link">{pageNumber}</a>
              </li>
            );
          } else {
            return (
              <li key={pageNumber} className="pagination__numbers">
                <a
                  className={`pagination__link ${parseInt(pageNumber) === currentPage ? "pagination__link--active" : ""}`}
                  onClick={() => loadPage(parseInt(pageNumber))}
                >
                  {pageNumber}
                </a>
              </li>
            );
          }
        })}
      </ul>
    );
  };

  const loadPage = (pageNumber: number) => {
    if (pageNumber < 0 || pageNumber >= props.totalPages || pageNumber === currentPageProp) {
      return;
    }
    history.push(`${props.link}?page=${pageNumber}`);
  };

  if (props.totalPages < 2) {
    return <></>;
  }

  return (
    <div className="pagination">
      <a className="pagination__direction" onClick={() => loadPage(currentPageProp - 1)}>
        Prev Page
      </a>
      {createPagination(currentPageProp, props.totalPages)}
      <a className="pagination__direction" onClick={() => loadPage(currentPageProp + 1)}>
        Next Page
      </a>
    </div>
  );
}
