import axios from "axios";
import { AnyAction, createAsyncThunk, createSlice, ThunkDispatch } from "@reduxjs/toolkit";
import { addAutoRemovableNotification } from "../notificationsReducer";

export interface Admin {
  email: string;
  fullName: string;
  type: string;
  organisationId: string;
  storiesCreated: number;
  _id: string;
}

export interface organisationPageReducerState {
  admins: Admin[];
}

const initialState: organisationPageReducerState = {
  admins: []
};

export const getAdmins = createAsyncThunk("organisation/getAdmins", async (organisationId: string | undefined) => {
  const response = await axios.get(`/api/admins?organisationId=${organisationId}`);
  return response.data as Admin[];
});

export const removeAdmin = createAsyncThunk("organisation/removeAdmin", async (admin: { id: string; organisationId: "" }, { dispatch }) => {
  const { id, organisationId } = admin;
  try {
    await axios.patch(`/api/admins/${id}`, { organisationId });
    dispatch(
      addAutoRemovableNotification({
        message: "Admin is removed",
        type: "success"
      })
    );
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const cause = e.response?.data || e.message;
      dispatch(
        addAutoRemovableNotification({
          message: `Not able to remove an admin: ${cause}`,
          type: "error"
        })
      );
    }
    throw new Error();
  }
  return id;
});

export const createAdmin = createAsyncThunk("organisation/createAdmin", async (admin: { email: string; type: string; organisationId?: string | undefined }, { dispatch }) => {
    const { email, type, organisationId } = admin;
    try {
      const response = await axios.post(`/api/admins`, { email, type, organisationId });
      dispatch(
        addAutoRemovableNotification({
          message: "Created an admin",
          type: "success"
        })
      );
      return response.data as Admin;
    } catch (e) {
      if (!axios.isAxiosError(e)) {
        throw new Error();
      }

      const cause = e.response?.data || e.message;
      const id = e.response?.data;

      if (e.response?.statusText !== "User already exist") {
        adminErrorHandler(dispatch, cause);
        throw new Error();
      }

      try {
        const response = await axios.patch(`/api/admins/${id}`, { organisationId });
        dispatch(
          addAutoRemovableNotification({
            message: "User already exist. Changed user's organisation",
            type: "success"
          })
        );
        return response.data as Admin;
      } catch (e) {
        adminErrorHandler(dispatch, "User already exist in another organisation");
        throw new Error();
      }
    }
  }
);

const adminErrorHandler = (dispatch: ThunkDispatch<unknown, unknown, AnyAction>, cause: unknown) => {
  dispatch(
    addAutoRemovableNotification({
      message: `Not able to create an admin: ${cause}`,
      type: "error"
    })
  );
};

export const organisationPageSlice = createSlice({
  name: "organisationPage",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAdmins.fulfilled, (state, action) => {
      state.admins = action.payload;
    });
    builder.addCase(removeAdmin.fulfilled, (state, action) => {
      state.admins = state.admins.filter(({ _id }) => _id !== action.payload);
    });
    builder.addCase(createAdmin.fulfilled, (state, action) => {
      state.admins.push(action.payload);
    });
  }
});

export default organisationPageSlice.reducer;
