import { useState } from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";
import RModal from "../../content/Modal";
import JoinRoom from "../../content/JoinRoom";
import { userSelector } from "../../../redux/userReducer/selectors";
import "./styles.scss";

export default function HomePage() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const user = useAppSelector(userSelector);

  const isSignedInUser = () => {
    return user.isAdmin;
  };

  const getDashboardButton = () => {
    if (!isSignedInUser()) {
      return null;
    }
    return (
      <Link className="button button--orange" to={"/dashboard"}>
        Dashboard
      </Link>
    );
  };

  const getSocketStatsButton = () => {
    if (!user.isSystemAdmin) {
      return null;
    }
    return (
      <Link className="button button--fuschia" to={"/socket-usage"}>
        Socket Stats
      </Link>
    );
  };

  const getOrganisationsButton = () => {
    if (!user.isSystemAdmin) {
      return null;
    }
    return (
      <Link className="button button--blue" to={"/organisations"}>
        Organisations
      </Link>
    );
  };

  const getMyOrganisationButton = () => {
    if ((!user.isOrganisationAdmin && !user.isSystemAdmin) || !user.organisationId) {
      return null;
    }
    return (
      <Link className="button button--yellow" to={`/organisation/${user.organisationId}`}>
        My Organisation
      </Link>
    );
  };

  const getLoginButton = () => {
    if (isSignedInUser()) {
      return null;
    }
    return (
      <a href="/api/auth/google" className="button button--purple">
        Login
      </a>
    );
  };

  const getLogoutButton = () => {
    if (!isSignedInUser()) {
      return null;
    }
    return (
      <a href="/api/auth/logout" className="button button--purple">
        Logout
      </a>
    );
  };

  const getJoinRoomButton = () => {
    return (
      <a
        onClick={() => {
          setIsModalOpen(true);
        }}
        className="button button--green"
      >
        Join Room
      </a>
    );
  };

  const getJoinViewRoom = () => {
    if (!isModalOpen) {
      return null;
    }
    return (
      <RModal isOpen={true} onRequestClose={() => setIsModalOpen(false)}>
        <JoinRoom onClose={() => setIsModalOpen(false)} />
      </RModal>
    );
  };

  return (
    <>
      <div className={"home-page"}>
        <div className="home-page__content">
          {getMyOrganisationButton()}
          {getOrganisationsButton()}
          {getSocketStatsButton()}
          {getDashboardButton()}
          {getJoinRoomButton()}
          {getLoginButton()}
          {getLogoutButton()}
        </div>
      </div>
      {getJoinViewRoom()}
    </>
  );
}
