import BackupsList from "../../content/BackupsList";
import NewBackup from "../../content/NewBackup";
import "./index.scss";

export default function BackupsPage() {
  return (
    <div className="backups-page">
      <p className="backups-page__title">Backups</p>
      <BackupsList />
      <NewBackup />
    </div>
  );
}
