import React, { useState } from "react";
import { useAppDispatch } from "../../../redux";
import { updateStoryUrlMapping, storyUrlMapping } from "../../../redux/storyUrlMappingReducer";

interface EditStoryUrlMappingProps {
  storyUrlMapping: storyUrlMapping;
  discard: () => void;
}

export default function EditStoryUrlMapping(props: EditStoryUrlMappingProps) {
  const [newStoryNaming, setNewStoryNaming] = useState(props.storyUrlMapping.storyNaming);
  const [newPrefixUrl, setNewPrefixUrl] = useState(props.storyUrlMapping.prefixUrl);
  const dispatch = useAppDispatch();

  const handleStoryNamingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const storyNaming  = e.target.value;
    setNewStoryNaming(storyNaming);
  };

  const handleChangePrefixUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
    const prefixUrl = e.target.value;
    setNewPrefixUrl(prefixUrl);
  }

  const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(updateStoryUrlMapping({ id: props.storyUrlMapping._id, storyNaming: newStoryNaming, prefixUrl: newPrefixUrl, organisationId: props.storyUrlMapping.organisationId }));
    props.discard();
  };

  return (
    <form className="rmodal" onSubmit={handleSave}>
      <p className="rmodal__title">Edit Story Mapping</p>
      <div className="input-wrapper">
        <span className="input-carrets">{">>>"}</span>
        <input className="input" autoFocus={true} onChange={handleStoryNamingChange} value={newStoryNaming} placeholder="Story Naming" />
        <span className="input-carrets">{"<<<"}</span>
      </div>
      <div className="input-wrapper">
        <span className="input-carrets">{">>>"}</span>
        <input className="input" onChange={handleChangePrefixUrl} value={newPrefixUrl} placeholder="Prefix Url" />
        <span className="input-carrets">{"<<<"}</span>
      </div>
      <div className="rmodal-actions">
        <a className="button button--fuschia" onClick={props.discard}>
          Cancel
        </a>
        <button type="submit" className="button button--blue">
          Save
        </button>
      </div>
    </form>
  );
}
