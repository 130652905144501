import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { getCookie } from "../../../utils/cookieUtils";
import { useAppSelector } from "../../../redux/hooks";
import { sessionsCountFromStore, sessionsFromStore } from "../../../redux/dashboardReducer/selectors";
import { Session, getAllSessions } from "../../../redux/dashboardReducer";
import { useAppDispatch } from "../../../redux";
import { addAutoRemovableNotification } from "../../../redux/notificationsReducer";
import RModal from "../Modal";
import EditSession from "../EditSession";
import RemoveSession from "../RemoveSession";
import RotateSessionToken from "../RotateSessionToken";
import UnsecureSession from "../UnsecureSession";
import Pagination from "../Pagination";
import { ReactComponent as Unlocked } from "./unlocked.svg";
import { ReactComponent as Locked } from "./locked.svg";
import { ReactComponent as Rotate } from "./rotate.svg";
import { ReactComponent as Info } from "./info.svg";
import "./index.scss";
import SessionExpirationInfo from "../SessionExpirationInfo";

export default function SessionList() {
  const sessions = useAppSelector(sessionsFromStore);
  const sessionsCount = useAppSelector(sessionsCountFromStore);
  const dispatch = useAppDispatch();

  const [activeSessionId, setActiveSessionId] = useState("");
  const [editSessionId, setEditSessionId] = useState("");
  const [removeSessionId, setRemoveSessionId] = useState("");
  const [secureSessionId, setSecureSessionId] = useState("");
  const [unsecureSessionId, setUnsecureSessionId] = useState("");
  const [infoSessionId, setInfoSessionId] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const page = parseInt(searchParams.get("page") || "0");

  useEffect(() => {
    dispatch(getAllSessions(page));
  }, [searchParams, sessionsCount]);

  const copyToClipBoard = (id: string, token?: string) => {
    const host = window.location.href.replace(window.location.pathname, "");
    if (token) {
      navigator.clipboard.writeText(`${host}/planningsession/${id}?token=${token}`);
    } else {
      navigator.clipboard.writeText(`${host}/planningsession/${id}`);
    }
    dispatch(addAutoRemovableNotification({ message: "Link Coppied", type: "success" }));
  };

  const getLinkToSession = (id: string, token?: string) => {
    if (token) {
      return (
        <Link to={`/planningsession/${id}?token=${token}`} className="button button--blue">
          Open
        </Link>
      );
    } else {
      return (
        <Link to={`/planningsession/${id}`} className="button button--blue">
          Open
        </Link>
      );
    }
  };

  const handleSelect = (id: string) => {
    if (activeSessionId && activeSessionId === id) {
      setActiveSessionId("");
    } else {
      setActiveSessionId(id);
    }
  };

  const renderEmptyDashboard = () => {
    if (page !== 0) {
      return <p className="text">Looks like you searched too far, try from the first page</p>;
    }
    return <p className="text">You haven't created any sessions yet</p>;
  };

  const isExpiredSecurityToken = (session: Session) => {
    if (session.token && session.expireDate) {
      const currentServerDate = JSON.parse(getCookie("serverTime") || "");
      const currentDate = currentServerDate ? new Date(currentServerDate) : new Date();
      return new Date(session.expireDate) < currentDate;
    }
    return false;
  };

  const getNavigationButtons = (session: Session) => {
    if (isExpiredSecurityToken(session)) {
      return (
        <a className="button button--yellow" onClick={() => setSecureSessionId(session._id)}>
          Renew Token
        </a>
      );
    }
    return (
      <>
        <a className="button button--yellow" onClick={() => copyToClipBoard(session._id, session.token)}>
          Copy Link
        </a>
        {getLinkToSession(session._id, session.token)}
      </>
    );
  };

  const renderSessionActions = (session: Session) => {
    return (
      <div className={`session__actions ${session._id === activeSessionId ? "session__actions--active" : ""}`}>
        {getNavigationButtons(session)}
        <a className="button button--green" onClick={() => setEditSessionId(session._id)}>
          Edit
        </a>
        <a className="button button--orange" onClick={() => setRemoveSessionId(session._id)}>
          Remove
        </a>
      </div>
    );
  };

  const renderSessionSecurityAttributes = (session: Session) => {
    if (!session.token) {
      return (
        <p onClick={() => setSecureSessionId(session._id)} className="session__lock session__lock--unsafe">
          <Unlocked />
        </p>
      );
    }
    return (
      <>
        <p onClick={() => setInfoSessionId(session._id)} className="session__info">
          <Info />
        </p>
        <p onClick={() => setUnsecureSessionId(session._id)} className="session__lock session__lock--safe" title="Foo bar">
          <Locked />
        </p>
        <p onClick={() => setSecureSessionId(session._id)} className="session__rotate">
          <Rotate />
        </p>
      </>
    );
  };

  const sortSessions = (s1: Session, s2: Session) => {
    return new Date(s1.dateCreated) > new Date(s2.dateCreated) ? -1 : 1;
  };

  const renderSessions = () => {
    if (sessions.length === 0) {
      return renderEmptyDashboard();
    }
    return sessions
      .slice(0, 8)
      .sort(sortSessions)
      .map((session) => {
        return (
          <div key={session._id} className="session" onClick={() => handleSelect(session._id)}>
            <div className="session__meta">
              <p className="session__title">{session.title}</p>
              <div className="session__security">{renderSessionSecurityAttributes(session)}</div>
            </div>
            {renderSessionActions(session)}
          </div>
        );
      });
  };

  const renderSecureSession = () => {
    if (!secureSessionId) {
      return;
    }

    const session = sessions.find(({ _id }) => _id === secureSessionId);

    if (!session) {
      return;
    }

    return (
      <RModal isOpen={true} onRequestClose={() => setSecureSessionId("")}>
        <RotateSessionToken session={session} closeModal={() => setSecureSessionId("")} />
      </RModal>
    );
  };

  const renderSessionExpirationInfo = () => {
    if (!infoSessionId) {
      return;
    }

    const session = sessions.find(({ _id }) => _id === infoSessionId);

    if (!session) {
      return;
    }

    return (
      <RModal isOpen={true} onRequestClose={() => setInfoSessionId("")}>
        <SessionExpirationInfo session={session} closeModal={() => setInfoSessionId("")} />
      </RModal>
    );
  };

  const renderUnsecureSession = () => {
    if (!unsecureSessionId) {
      return;
    }

    const session = sessions.find(({ _id }) => _id === unsecureSessionId);

    if (!session) {
      return;
    }

    return (
      <RModal isOpen={true} onRequestClose={() => setUnsecureSessionId("")}>
        <UnsecureSession session={session} closeModal={() => setUnsecureSessionId("")} />
      </RModal>
    );
  };

  const renderRemoveSession = () => {
    if (!removeSessionId) {
      return;
    }

    const session = sessions.find(({ _id }) => _id === removeSessionId);

    if (!session) {
      return;
    }

    return (
      <RModal isOpen={true} onRequestClose={() => setRemoveSessionId("")}>
        <RemoveSession session={session} closeModal={() => setRemoveSessionId("")} />
      </RModal>
    );
  };

  const renderEditSession = () => {
    if (!editSessionId) {
      return;
    }
    const session = sessions.find(({ _id }) => _id === editSessionId);

    if (!session) {
      return;
    }
    return (
      <RModal isOpen={true} onRequestClose={() => setEditSessionId("")}>
        <EditSession session={session} discard={() => setEditSessionId("")} />
      </RModal>
    );
  };

  const renderPagination = () => {
    return <Pagination totalPages={Math.ceil(sessionsCount / 8)} link={`/dashboard`} />;
  };

  return (
    <>
      <div className="sessions-container">{renderSessions()}</div>
      {renderEditSession()}
      {renderRemoveSession()}
      {renderSecureSession()}
      {renderUnsecureSession()}
      {renderSessionExpirationInfo()}
      {renderPagination()}
    </>
  );
}
