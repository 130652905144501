import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addAutoRemovableNotification } from "../notificationsReducer";

export interface Room {
  roomId: string;
  userNames: string[];
}

export interface AdminDashboardReducerState {
  roomArr: Room[];
}

const initialState: AdminDashboardReducerState = {
  roomArr: []
};

export const getAllRooms = createAsyncThunk("rooms/getAllRooms", async (params, { dispatch }) => {
  try {
    const response = await axios.get(`/api/sessionrooms`);
    return response.data as Room[];
  } catch (error) {
    if (axios.isAxiosError(error))
      dispatch(
        addAutoRemovableNotification({
          message: `Not able to get data: ${error.message}`,
          type: "error"
        })
      );
    throw new Error();
  }
});

export const roomSlice = createSlice({
  name: "rooms",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllRooms.fulfilled, (state, action) => {
      state.roomArr = action.payload;
    });
  }
});

export default roomSlice.reducer;
