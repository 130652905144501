import { removeSession, Session } from "../../../redux/dashboardReducer";
import { useAppDispatch } from "../../../redux";

interface RemoveSessionProps {
  closeModal: () => void;
  session: Session;
}

export default function RemoveSession(props: RemoveSessionProps) {
  const dispatch = useAppDispatch();

  const removeHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(removeSession(props.session._id));
    props.closeModal();
  };

  return (
    <form className="rmodal" onSubmit={removeHandler}>
      <p className="rmodal__title">Delete Session</p>
      <div className="input-wrapper">
        <span className="input-carrets">{">>>"}</span>
        <input className="input" disabled value={props.session.title} />
        <span className="input-carrets">{"<<<"}</span>
      </div>
      <div className="rmodal-actions">
        <a className="button button--fuschia" onClick={props.closeModal}>
          No
        </a>
        <button type="submit" className="button button--blue" autoFocus={true}>
          Yes
        </button>
      </div>
    </form>
  );
}
