import { useAppSelector } from "../../../redux/hooks";
import { sessionRoomSelector } from "../../../redux/sessionRoomReducer/selectors";
import { userSelector } from "../../../redux/userReducer/selectors";
import { selectedStorySelector } from "../../../redux/userReducer/selectors";
import { getSelectedStory, replaceTextToLinks } from "../../../utils/sessionUtils";
import sessionHelper from "../../../services/sessionHelper";

export default function EstimationMetadataComponent() {
  const session = useAppSelector(sessionRoomSelector);
  const { isAdmin } = useAppSelector(userSelector);
  const selectedStoryId = useAppSelector(selectedStorySelector);

  const getTitleSection = () => {
    const seletedStory = getSelectedStory(session, selectedStoryId);
    if (!seletedStory?.title) {
      return null;
    }
    return (
      <div className="estimation-metadata-component__story-title">
        <span className="estimation-metadata-component__story-title-name green-text">{`<Name>`}</span>
        <span className="estimation-metadata-component__story-title-value">{replaceTextToLinks(seletedStory.processedTitle)}</span>
      </div>
    );
  };

  const getDescriptionSection = () => {
    const seletedStory = getSelectedStory(session, selectedStoryId);
    if (!seletedStory?.description) {
      return null;
    }
    return (
      <div className="estimation-metadata-component__story-description">
        <span className="estimation-metadata-component__story-description-name green-text">{`<Description>`}</span>
        <span className="estimation-metadata-component__story-description-value">{replaceTextToLinks(seletedStory.processedDescription)}</span>
      </div>
    );
  };

  const getFinalVoteSection = () => {
    const seletedStory = getSelectedStory(session, selectedStoryId);
    if (!seletedStory?.finalVote) {
      return null;
    }
    return (
      <div className="estimation-metadata-component__story-vote">
        <span className="estimation-metadata-component__story-vote-name green-text">{`<Final Vote>`}</span>
        <span className="estimation-metadata-component__story-vote-value">{seletedStory.finalVote}</span>
      </div>
    );
  };

  const renderCloneButton = () => {
    if (!isAdmin) {
      return;
    }
    return (
      <a className="button" onClick={() => sessionHelper.cloneSession(session)}>
        Clone Session
      </a>
    );
  };

  return (
    <div className="estimation-metadata-component">
      {renderCloneButton()}
      <div className="estimation-metadata-component__head">{session?.title}</div>
      <div className="estimation-metadata-component__story-data">
        {getTitleSection()}
        {getDescriptionSection()}
        {getFinalVoteSection()}
      </div>
    </div>
  );
}
